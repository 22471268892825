import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useRequest } from "./api";
import { controlHeader } from "../components";

export const useArtist = () => {
  const [loading, setLoading] = useState(true);
  const [artist, setArtist] = useState();
  const { slug } = useParams();
  const request = useRequest();
  const navigate = useNavigate();
  useEffect(controlHeader, []);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await request
        .send({ url: `artists/${slug}`, method: "GET" })
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            setArtist(response.data);
          }
        });
    };
    getData();
  }, [slug]);

  return {
    loading,
    artist,
    slug,
    navigate,
  };
};

export const useMusicArtist = () => {
  const { slug } = useParams();
  const [musics, setMusics] = useState(null);
  const [loading, setLoading] = useState(false);
  const request = useRequest();

  
  useEffect(() => {
    const get = async () => {
      setLoading(true);
      await request.send({ url: `artists/musics/${slug}`, method: "GET" }).then(
        (response) => {
          if (response.status === 200) {
            setMusics(response.data);
            setLoading(false);
          }
        }
      );
    };
  
    get();
  }, [slug]);

  return {
    musics,
    loading,
    slug,
  };
};



export const useMusicVideos= () =>{
const [video , setVideo] = useState(null);
const [loading , setLoading] = useState(false);
const slug = useParams;
const request = useRequest();

useEffect (() => {

  const getVideo = async () =>{
    setLoading(true)
    await request.send({url:`artists/videos/${slug}`, method: "GET"}).then(
      (response) =>{
        if(response.status === 200){
          setVideo(response.data)
          setLoading(false)
        }
      }
    )
  }
  getVideo()
} , [slug])

return {
  loading,
  video,
  slug
};
}


