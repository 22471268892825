import { Link } from 'react-router-dom';
import './style.scss';
import { useMusicContext } from '../../../contexts';


export const HorizontalImage = ({ icon, number, cover, artist_name, track_name, duration, artist_slug, playlist, musicObject }) => {
    const { source, statusPlay, onTogglePlay, playMusichandler } = useMusicContext()
    return (
        <div className='HorizontalBoxImage' >
            <div className="d-flex align-items-center">
                <div className='number'>
                    <p>{number}</p>
                </div>
                <div className='imageHorizontal' onClick={() => {
                    if (musicObject && playlist) {
                        if (source?.slug === musicObject?.slug) {
                            onTogglePlay()
                        } else {
                            playMusichandler(playlist, [...playlist].findIndex(item => item?.slug === musicObject?.slug))
                        }
                    }
                }}>
                    <img
                        className='img'
                        src={cover}
                        alt='pictuer'
                    />
                    {
                        source?.slug === musicObject?.slug
                            ?
                            statusPlay ?
                                <div style={{ color: "var(--primary)" }} className="spinner-grow spinner-grow-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <svg style={{ display: "block" }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.9" cx="16" cy="16" r="16" fill="var(--primary)" />
                                    <path d="M12.2351 11.1258V20.8764C12.2351 21.62 13.0539 22.0717 13.6845 
                        21.667L21.3457 16.7917C21.9292 16.4247 21.9292 15.5776 21.3457 15.2011L13.6845
                        10.3353C13.0539 9.93055 12.2351 10.3823 12.2351 11.1258Z" fill="white" />
                                </svg>
                            :
                            <svg className='play' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.9" cx="16" cy="16" r="16" fill="var(--primary)" />
                                <path d="M12.2351 11.1258V20.8764C12.2351 21.62 13.0539 22.0717 13.6845 
                    21.667L21.3457 16.7917C21.9292 16.4247 21.9292 15.5776 21.3457 15.2011L13.6845
                    10.3353C13.0539 9.93055 12.2351 10.3823 12.2351 11.1258Z" fill="white" />
                            </svg>
                    }
                </div>

                <div className='title'>
                    <Link to={"/artist/" + artist_slug} className='name'>
                        <p>{artist_name}</p>
                    </Link>
                    <div className='artist'>
                        <p>{track_name}</p>
                    </div>
                </div>
            </div>
            <div className='time'>
                <p>{duration}</p>
                {icon
                    ?
                    <div className='ps-3'>
                        {icon}
                    </div>
                    :
                    null
                }
            </div>
        </div >
    )
}

export const HorizontalImage2 = ({ cover, track_name, number, artist_name, artist_slug, duration, musicObject, playlist }) => {
    const { source, statusPlay, onTogglePlay, playMusichandler } = useMusicContext()
    return (
        <div className="musicbox px-sm-3 px-1 py-3">
            <div className='avatar d-flex align-items-center' onClick={() => {
                if (musicObject && playlist) {
                    if (source?.slug === musicObject?.slug) {
                        onTogglePlay()
                    } else {
                        playMusichandler(playlist, [...playlist].findIndex(item => item.slug === musicObject?.slug))
                    }
                }
            }}>
                <div className='num'>
                    <span className='px-3'>{number}</span>
                    <i className='fas fa-play px-3' />
                </div>
                <div className='imagenum d-flex align-items-center justify-content-center'>
                    <img
                        src={cover}
                        width={50}
                        height={50}
                        alt='artist'
                        style={{ borderRadius: "5px" }}
                    />
                    {
                        source?.slug === musicObject?.slug
                            &&
                            statusPlay ?

                            <div style={{ color: "var(--primary)" }} className="loadering spinner-grow spinner-grow-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <div className='item'>
                <div className='info'>
                    <div className='name_music'>
                        <Link to={"/music/" + musicObject?.slug}>
                            {track_name}
                        </Link>
                    </div>
                    <div className='artict_music'>
                        <Link to={"/artist/" + artist_slug}>
                            {artist_name}
                        </Link>
                    </div>
                </div>
                <div className='time'>
                    <div className='px-3 d-none d-sm-block' />
                    <div>{duration}</div>
                    <div className='px-3' />
                </div>
            </div>
        </div>
    )
}
