import { toast } from 'react-toastify';
import { useApp } from '../contexts';
import { useRequest } from './api';

export const useAuth = () => {
    const { login, user } = useApp();

    const {send} = useRequest();
    
    const onLogin = async ({ data, url, massege, Result }) => {
        await send({ data, url, method: "post" }).then((response) => {
            if (response.status === 200) {
                Result()
                login(response.data)
                // toast.success(massege, { theme: "colored" })
            } else {
                toast.error("!! اطلاعات اشتباه است", { theme: "colored" });
            }
        })
    }

    const onRegister = async ({ data, url, massege, Result }) => {
        await send({ data, url, method: "post" }).then((response) => {
            if (response.status === 200) {
                Result()
                // toast.success(massege, { theme: "colored" })
            } else {
                toast.error(response?.status?.data?.message, { theme: "colored" });
            }
        })
    }


    return { onLogin, onRegister , user }

}
