import React, { useEffect, useState } from 'react'
import { useTheme } from '../../contexts';
import { useNavigate } from 'react-router';
import { useAuth } from '../../hooks';
import { useForm } from 'react-hook-form';
import { images } from '../../assets';
import { Verify } from './verify';
import { Login } from './login';
import './style.scss';
import { controlFooter } from '../../components/layout/footer';
import { controlHeader } from '../../components';

export const Auth = () => {
    useEffect(controlFooter, [])
    useEffect(controlHeader, [])
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { onLogin, onRegister } = useAuth();
    const [phone, setPhone] = useState();
    const [page, setPage] = useState('login');
    const { register, handleSubmit, formState: { errors } } = useForm();


    return (
        <div className='Register'>
            <div className="Registercard">
                <div className='goback' onClick={() => navigate(-1)}>
                    <i className='far fa-arrow-left' />
                </div>
                <div className="gradients"></div>
            </div>
            <div className="Registerform">
                <div className="logo">
                    {theme === 'light' ?
                        <img width={155} height={55} alt="logo" src={images.logolight} />
                        :
                        <img width={155} height={55} alt="logo" src={images.logodark} />
                    }
                </div>
                <div className='content'>
                    {page === 'login'
                        ?
                        <Login
                            setPhone={setPhone}
                            setPage={setPage}
                            onRegister={onRegister}
                            register={register}
                            handleSubmit={handleSubmit}
                            errors={errors} />
                        :
                        <Verify
                            handleSubmit={handleSubmit}
                            onLogin={onLogin}
                            phone={phone}
                            register={register}
                            navigate={navigate}
                            setPage={setPage}
                        />
                    }
                </div>
            </div>
        </div>
    )
}
