import { Route, Routes } from 'react-router'
import { useApp } from '../contexts';
import Layout from '../components/layout';
import {
    Account,
    Category,
    Home,
    Library,
    Suggest,
    MusicVideos,
    MusicVideo,
    EditProfile,
    Settings,
    Auth,
    Music,
    Artist,
    Landing,
    Videos,
    Video,
    Search,
    Rules,
    Bookmark,
    Liked,
    Upload,
    Uploadfile,
    Upgrade,
} from '../pages';


const Router = () => {
    const { user } = useApp();

    return (
        <Layout>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/category' element={<Category />} />
                <Route path='/landing/:slug' element={<Landing />} />
                <Route path='/videos/:slug/:type' element={<Videos />} />
                <Route path='/video/:slug' element={<Video />} />
                <Route path='/music-videos' element={<MusicVideos />} />
                <Route path='/music-videos/:slug' element={<MusicVideo />} />
                <Route path='/artist/*' element={<Artist />} />
                <Route path='/music/*' element={<Music />} />
                <Route path='/suggest' element={<Suggest />} />
                <Route path='/search' element={<Search />} />
                <Route path='/rules' element={<Rules />} />
                <Route path='/library/*' element={user
                    ?
                    <Routes>
                        <Route path='/' element={<Library />} />
                        <Route path='/liked' element={<Liked />} />
                        <Route path='/bookmark' element={<Bookmark />} />
                        <Route path='/upload' element={<Upload />} />
                        <Route path='/uploadfile' element={<Uploadfile />} />
                    </Routes>
                    :
                    <Auth />}
                />
                <Route path="/account/*" element={user
                    ?
                    <Routes>
                        <Route path='/' element={<Account />} />
                        <Route path='/edit-profile' element={<EditProfile />} />
                        <Route path='/settings' element={<Settings />} />
                        {user?.role == "client" && <Route path='/upgrade' element={<Upgrade />} />}
                    </Routes>
                    :
                    <Auth />}
                />
            </Routes>
        </Layout>
    )
}
export default Router