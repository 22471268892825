import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { getMobileOperatingSystem } from '../../../assets/utils';

export const More = ({ source, onHide, setMode, operator }) => {
    const ref = useRef()
    const musicData = localStorage.getItem('music');
    const musicAll = musicData ? JSON.parse(musicData) : []

    useOutSide(ref, onHide)


    return (
        <div ref={ref} className='moreMusic'>
            <header className='d-flex align-items-center justify-content-center'>
                <div className='text-center'>
                    <img src={source?.cover} alt="cover" />
                    <div className='mt-3'>{source?.name}</div>
                    <div className='mt-2 gray'>{source?.artistinfo?.userinfo?.name || source?.artist?.userinfo?.name}</div>
                </div>
            </header>
            <section>
                <div onClick={() => {
                    operator.onBookmark(source?.slug)
                }} className='item '>
                    <i id='bookmark' className={`${source?.properties?.isBookmark ? "fas" : "far"} ${operator.loading.bookmark ? 'spinner-border' : 'fa-bookmark'}`} style={{ borderWidth: 2 }}></i>
                    {source?.properties?.isBookmark === 'fas' ? " حذف از " : " افزودن به "} لیست ذخیره شده ها
                </div>
                {getMobileOperatingSystem() === 'iOS' ?
                    null : (
                        <div onClick={() => {
                            let newMusicAll = [...musicAll];
                            if (newMusicAll.filter(item => item.slug !== source?.slug)) {
                                newMusicAll.push(source)
                                localStorage.setItem('music', JSON.stringify(newMusicAll))
                            }
                            if (window.AndroidInterface) {
                                if (window.AndroidInterface.downloadFile) {
                                    window.AndroidInterface.downloadFile(source?.url?.normal)
                                }
                            }
                        }} className='item '>
                            <i className={'far fa-cloud-download'}></i>
                            دانلود فایل
                        </div>
                    )}
                <div onClick={async () => {
                    try {
                        if (navigator.canShare) {
                            await navigator.share({
                                title: source?.name,
                                text: 'آهنگ ' + source?.name + " با صدای " + source?.artistinfo?.userinfo?.name || source?.artist?.userinfo?.name + " را در خراسان مدیا گوش کنید \n\n\n لینک آهنگ \n",
                                url: "https://khorasanmedia.ir/music/" + source?.slug,
                            })
                        } else {
                            if (window.AndroidInterface) {
                                window.AndroidInterface.share('آهنگ ' + source?.name + " با صدای " + source?.artistinfo?.userinfo?.name || source?.artist?.userinfo?.name + " را در خراسان مدیا گوش کنید \n\n\n لینک آهنگ \n https://khorasanmedia.ir/music/" + source?.slug);
                            }
                        }
                    } catch (error) {

                    }
                }} className='item '>
                    <i className={`fal fa-share-alt`}></i>
                    اشتراک گذاری
                </div>

                <div className='item'>
                    <Link onClick={() => {
                        onHide();
                        setMode('minimize');
                    }} style={{ color: "#fff" }} to={"/artist/" + source?.artistinfo?.slug || source?.artist?.slug}>
                        <i className={`fal fa-user`}></i>
                        صفحه شخصی خواننده
                    </Link>
                </div>



            </section>
        </div>
    )
}

function useOutSide(ref, action) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                action();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


