import React, { useEffect, useRef, useState } from "react";

export const Form = ({
  children,
  submit,
  clear = false,
  typeData,
  className,
  style,
  loading = '<div className="d-flex align-items-center justify-content-center h-100"><div className="spinner-border spinner-border-sm mr-2"></div> درحال بررسی </div>',
}) => {

  const [sending, setSending] = useState(false)
  const form = useRef();
  let inputs = new FormData();
  if (typeData === "object") {
    inputs = {};
  }

  useEffect(() => {
    if (clear && form.current) {
      form.current.reset();
      for (const [key, input] of Object.entries(
        form.current.querySelectorAll("input,select,textarea")
      )) {
        input.value = null;
      }
    }
  }, [clear, form.current]);

  useEffect(() => {
    const Button = form.current.querySelector('button[type="submit"]');
    if (sending) {
      Button.innerHTML = loading;
    }
  }, [loading])


  const Submit = (e) => {
    e.preventDefault();
    const Button = form.current.querySelector('button[type="submit"]');
    const inputsComma = form.current.querySelectorAll('.removeComma');

    [...inputsComma].map((i) => i.value = i.value.replace(/,/g, ""))

    const ButtonHtml = Button.innerHTML;
    Button.disabled = true;
    Button.innerHTML = loading;

    for (const [key, input] of Object.entries(
      form.current.querySelectorAll("input,select,textarea")
    )) {
      if (String(input.name).length) {
        if (typeData === "object") {
          inputs[input.name] = input.value;
        } else {
          if (input.type == "radio") {
            if (input.checked) {
              inputs.append(input.name, input.value);
            }
          } else {
            if (input.type === "file") {
              if (input.files[0]) {
                inputs.append(input.name, input.files[0]);
              }
            } else {
              if (typeof value === "object") {
                for (const ob of Object.entries(input.value)) {
                  if (ob[1]) {
                    inputs.append(input.name + "[]", ob[1]);
                  }
                }
              } else {
                if (input.value) {
                  inputs.append(input.name, input.value);
                }
              }
            }
          }
        }
      }
    }
    setSending(true);
    Send(inputs).then(() => {
      setSending(false);
      Button.disabled = false;
      Button.innerHTML = ButtonHtml;
      if (typeData === "object") {
        inputs = {};
      } else {
        inputs = new FormData();
      }
    });
  };
  const Send = async (data) => {
    return await submit(data);
  };
  return (
    <form ref={form} onSubmit={Submit} className={className} style={style}>
      {children}
    </form>
  );
};
