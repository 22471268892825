import React from 'react'
import './style.scss'
import { useApp, useTheme } from '../../contexts'
import { useNavigate } from 'react-router'

export const Settings = () => {
  const { toggleTheme, theme } = useTheme();
  const nav = useNavigate()
  const { logout } = useApp()

  return (
    <div className='container'>
      <div className="borderb pb-3">
        <span>تم :</span>
        <div className="d-flex justify-content-between mt-3">
          <div className="col-5">
            <div onClick={() => toggleTheme()} className={`themeimg ${theme === 'light' && "themeimg themeactive"}`}>
              <img src={require('../../assets/images/light.png')} alt="lightimage" />
            </div>
          </div>
          <div className="col-5">
            <div onClick={() => toggleTheme()} className={`themeimg ${theme === 'dark' && "themeimg themeactive"}`}>
              <img src={require('../../assets/images/dark.png')} alt='' />
            </div>
          </div>
        </div>
      </div>
      <button className='logoutbtn mt-4' onClick={() => { logout(); nav('/account') }}>
        <div className="trans">
          <i className='icon ti-import'></i>
        </div>
        خروج از حساب
      </button>
    </div>
  )
}