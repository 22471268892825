
import { useState, useEffect } from 'react';
import { ControlBar, Player, ProgressControl, Shortcut } from 'video-react';
import { useVideoContext } from '../../../contexts';
import { useWindowDimensions } from '../../../hooks';
import 'video-react/dist/video-react.css';
import './style.scss';
import { SVG } from '../../commons';

export function VideoPlayer() {

    const {
        Play, Pause, ChangePlaybackRateRate, settogglePlay, ChangeVolume, valueVolume,
        playerVideo, FullScreen, ToggleMuted, ChangeCurrentTime, togglePlay, end, setEnd,
        volume, screen, source, setSource, resolution, ChangeQuality
    } = useVideoContext()


    const ClearProps = ({ children }) => {
        return (
            <>
                {children}
            </>
        )
    }

    const FunctionSpeed = () => {
        const speeds = [0.25, 0.5, 1, 1.25, 1.5, 1.75, 2]
        return speeds.map((speed, i) => <div className='item' onClick={ChangePlaybackRateRate(speed)} key={i}>{speed === 1 ? 'Normal' : speed}</div>)
    }

    const [endBottomPlayer, setendBottomPlayer] = useState(window.innerHeight)
    const { height } = useWindowDimensions();

    useEffect(() => {
        const footer = window.document.getElementsByClassName('footer');
        if (footer) {
            if (footer[0]) {
                setendBottomPlayer(footer[0].clientHeight)
            }
        }
    }, [height]);
    
    function changeOrientation(orientation) {
        if (window.AndroidInterface) {
            window.AndroidInterface.changeOrientation(orientation);
        } else if (window.screen && window.screen.orientation) {
            const orientationLock = window.screen.orientation.lock;

            if (orientationLock) {
                orientationLock.call(window.screen.orientation, orientation)
                    .catch(error => {
                        console.error('Error changing orientation:', error);
                    });
            } else {
                console.warn('Screen Orientation API not supported');
            }
        } else {
            console.warn('Orientation change not supported in this environment');
        }
    }

    useEffect(() => {
        if (screen) {
            changeOrientation('landscape')
        } else {
            changeOrientation('portrait')
        }
    }, [screen])

    return (

        <div style={{ display: source ? "flex" : "none", bottom: endBottomPlayer }} className={`videoPlayer ${!playerVideo.current?.getState()?.player?.isFullscreen && 'minimize'}`}>
            <Player
                playsInline={false}
                autoPlay
                fluid={false}
                width="100%" height="100%"
                src={source?.url?.[resolution]}
                onPlay={() => {
                    settogglePlay(true);
                    setEnd(false)
                }}

                onPause={() => { settogglePlay(false) }}
                onEnded={() => { setEnd(true) }}
                muted={volume}
                poster={source?.cover || ""}
                ref={pl => {
                    playerVideo.current = pl
                }}
            >
                <Shortcut clickable={false} dblclickable={false} />
                {screen ?
                    <ControlBar >

                        <ProgressControl />
                        <div className="controlBar d-flex justify-content-between align-items-center">
                            <div className='d-flex justify-content-center align-items-center' id='myDIV'>
                                <ClearProps >
                                    <button onClick={FullScreen} className="controlPlay d-flex justify-content-center align-items-center">
                                        {screen === false ? <SVG.BtnFullScreen /> : <SVG.BtnUnFullScreen />}
                                    </button>


                                    <div className="dropSetting dropup">
                                        <button data-bs-toggle="dropdown" aria-expanded="false" className="controlPlay d-flex justify-content-center align-items-center">
                                            <i className='icon ti-settings' />
                                        </button>
                                        <ul className='dropdown-menu' onClick={(event) => event.stopPropagation()}>
                                            <li className='li' onClick={() => {
                                                const element = window.document.getElementById('list-resolution');
                                                if (element) {
                                                    element.classList.toggle('active')
                                                }
                                                const elementSpeed = window.document.getElementById('list-speed');
                                                if (elementSpeed) {
                                                    elementSpeed.classList.remove('active')
                                                }
                                            }}>
                                                <div className='d-flex justify-content-between align-items-center w-100' >
                                                    <span>کیفیت پخش</span>
                                                    <i className="fal fa-chevron-left" />
                                                </div>
                                                <div id='list-resolution' className={`listTow `}>
                                                    {source?.url?.hq ?
                                                        <div className='item' onClick={() => { ChangeQuality('hq') }}>کیفیت بالا</div> : null}
                                                    <div className='item' onClick={() => { ChangeQuality('normal') }}>کیفیت معمولی</div>
                                                </div>
                                            </li>
                                            <li className='li' onClick={() => {
                                                const elementResolution = window.document.getElementById('list-resolution');
                                                if (elementResolution) {
                                                    elementResolution.classList.remove('active')
                                                }
                                                const element = window.document.getElementById('list-speed');
                                                if (element) {
                                                    element.classList.toggle('active')
                                                }
                                            }}>
                                                <div className='d-flex justify-content-between align-items-center w-100'>
                                                    <span>سرعت پخش</span>
                                                    <i className="fal fa-chevron-left" />
                                                </div>
                                                <div id='list-speed' className={`list `}>
                                                    <FunctionSpeed />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </ClearProps>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <ClearProps>
                                    <div className='volume d-flex justify-content-end align-items-center'>
                                        <input dir='ltr' type="range"
                                            id='myinput' min={0} max={1}
                                            value={valueVolume}
                                            onChange={({ target: { value } }) => ChangeVolume(value)}
                                            defaultValue={playerVideo?.current?.volume} step={0.1}
                                            className='range'
                                        />
                                        <button onClick={ToggleMuted} className='controlPlay'>
                                            {volume === false ? <SVG.BtnVolume /> : <SVG.BtnMuteVolume />}
                                        </button>
                                    </div>
                                </ClearProps>
                                <ClearProps>
                                    {!end && <button onClick={() => ChangeCurrentTime(15)} className="controlPlay d-flex justify-content-center align-items-center">
                                        <i className='icon ti-control-forward' />
                                    </button>}
                                </ClearProps>
                                <ClearProps>
                                    <button onClick={togglePlay ? Pause : Play} className="controlPlay d-flex justify-content-center align-items-center">
                                        {togglePlay ?
                                            <i className='icon ti-control-pause' />
                                            :
                                            end
                                                ?
                                                <i className='icon ti-control-play' />
                                                :
                                                <i className='icon ti-control-play' />
                                        }
                                    </button>
                                </ClearProps>
                                <ClearProps>
                                    {!end && <button onClick={() => ChangeCurrentTime(-15)} className="controlPlay d-flex justify-content-center align-items-center">
                                        <i className='icon ti-control-backward' />
                                    </button>}
                                </ClearProps>
                            </div>
                        </div>
                    </ControlBar>
                    : null}
            </Player>
            {!playerVideo.current?.getState()?.player?.isFullscreen &&
                <div className='w-100 d-flex justify-content-between align-items-center'>
                    <div className='ms-2' onClick={FullScreen} >
                        <div>{source?.name}</div>
                        <div>{source?.artist?.user?.name}</div>
                    </div>
                    <i onClick={() => { setSource(null); Pause(); }} className='icon ti-close'></i>
                </div>
            }
        </div>

    )
}