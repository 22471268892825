import { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { SliderPlayer } from './slider-player';
import { PlayList } from './playlist';
import { More } from './more';
import { Renger } from './renger';
import { useMusicContext, useVideoContext } from '../../../contexts';
import { useOperator, useWindowDimensions } from '../../../hooks';
import '@splidejs/react-splide/css';
import './style.scss';
import { getMobileOperatingSystem } from '../../../assets/utils';
export function MusicPlayer() {
    const { musicPlayer,
        setMusicPlayer,
        source,
        setSource,
        nextMusic,
        previousMusic,
        repeatmusic,
        setRepeatmusic,
        repeatplaylist,
        setRepeatplaylist,
        statusPlay,
        setStatusPlay,
        player,
        onPlay,
        onPause,
        onTogglePlay,
        randomPlay,
        random,
        setRandom,
        playlist,
        quality,
        setQuality,
        setCurrentmusic,
        currentmusic
    } = useMusicContext();

    const { togglePlay, Pause, screen, } = useVideoContext()
    const slider = useRef()
    const radinowPlayer = useRef();
    const [mode, setMode] = useState('full');
    const [showMore, setShowMore] = useState(false);

    const repeatmusichandler = () => {
        setRepeatmusic(true)
        setRepeatplaylist(false)
    }

    const repeatplaylisthandler = () => {
        setRepeatmusic(false)
        setRepeatplaylist(true)
    }

    const nonrepeathandler = () => {
        setRepeatmusic(false)
        setRepeatplaylist(false)
    }

    useEffect(() => {
        if (screen || togglePlay) {
            closeclick();
        }
    }, [togglePlay, screen])


    const closeclick = () => {
        onPause();
        setMusicPlayer(false);
        setSource(null);
    }

    const [endBottomPlayer, setendBottomPlayer] = useState(window.innerHeight)

    useEffect(() => {
        if (source) {
            if (slider.current) {
                slider.current.go(currentmusic)
            }
            if (radinowPlayer.current) {
                if (radinowPlayer.current.style.bottom == '0px' || radinowPlayer.current.style.bottom == '') {
                    const footer = window.document.getElementsByClassName('footer');

                    if (footer) {
                        if (footer[0]) {
                            footer[0].style.bottom = "-300px";
                        }
                    }
                }
            }
        }
    }, [source, currentmusic])


    useEffect(() => {
        setSource([...playlist][currentmusic]);
    }, [currentmusic])


    const { height } = useWindowDimensions();

    useEffect(() => {
        const footer = window.document.getElementsByClassName('footer');
        if (footer) {
            if (footer[0]) {
                const bottom = (window.innerHeight - footer[0].clientHeight) - ((window.innerHeight * 10) / 100);
                setendBottomPlayer(bottom)
            }
        }
    }, [height])

    useEffect(() => {
        setBottomPlayer(-endBottomPlayer);
    }, [endBottomPlayer])

    useEffect(() => {
        if (!musicPlayer) {

            resetScrollBody();
        } else {
            setBottomPlayer(0)
            disableScrollBody();
        }
    }, [musicPlayer])

    const stopBrowserBack = callback => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = () => {
            window.history.replaceState(null, "", window.location.href);
            callback();
        };
    };

    const startBrowserBack = () => {
        window.onpopstate = undefined;
    };

    const disableScrollBody = () => {
        window.document.body.style.overflow = "hidden";
        // Disable scrolling.
        window.document.ontouchmove = function (e) {
            e.preventDefault();
        }
    }

    const resetScrollBody = () => {
        window.document.body.style.overflow = "auto";
        // Enable scrolling.
        window.document.ontouchmove = function (e) {
            return true;
        }
        const footer = window.document.getElementsByClassName('footer');
        if (footer) {
            if (footer[0]) {
                footer[0].style.bottom = "0px";
            }
        }
    }

    useEffect(() => {
        if (mode == 'playlist') {
            stopBrowserBack(() => {
                setMode('full');
                disableScrollBody();
            });
        }
        if (mode == 'full') {
            stopBrowserBack(() => {
                setBottomPlayer(-endBottomPlayer);
                resetScrollBody();
                setMode('minimize');
            });
        }

        if (mode == 'minimize') {
            startBrowserBack();
            setBottomPlayer(-endBottomPlayer);
            resetScrollBody();
        }
    }, [mode])

    const setBottomPlayer = (value) => {
        const bottomValue = value * -1;
        if (bottomValue == 0) {
            setMode('full')
        }

        if (bottomValue == endBottomPlayer) {
            setMode('minimize')
        }

        if (radinowPlayer.current) {
            radinowPlayer.current.style.bottom = value + 'px';
        }

        const footer = window.document.getElementsByClassName('footer');
        const PlayerHeaderMini = window.document.getElementById('PlayerHeaderMini');
        const PlayerHeader = window.document.getElementById('PlayerHeader');
        if (footer) {
            if (footer[0]) {
                footer[0].style.bottom = bottomValue - endBottomPlayer + 'px';
            }
        }

        if (PlayerHeaderMini && PlayerHeader) {
            PlayerHeaderMini.style.opacity = bottomValue / window.innerHeight;
            PlayerHeaderMini.style.zIndex = 1;
            PlayerHeader.style.opacity = bottomValue + 2 / window.innerHeight;
            PlayerHeader.style.zIndex = 0;
            if (bottomValue == endBottomPlayer) {
                PlayerHeaderMini.style.opacity = 1;
                PlayerHeaderMini.style.zIndex = 1;
                PlayerHeader.style.opacity = 0;
                PlayerHeader.style.zIndex = 0;
            }
            if (bottomValue == 0) {
                PlayerHeaderMini.style.opacity = 0;
                PlayerHeaderMini.style.zIndex = 0;
                PlayerHeader.style.opacity = 1;
                PlayerHeader.style.zIndex = 12;

            }
        }
    }

    const musicData = localStorage.getItem('music');
    const musicAll = musicData ? JSON.parse(musicData) : []


    const operator = useOperator({
        data: source,
        setData: setSource,
        slug: source?.slug,
        properties: source?.properties,
        urlLike: `musics/action/${source?.slug}`,
        urlBookmark: `musics/bookmark/`
    });

    return (

        musicPlayer ?
            <>
                <audio ref={player} id="#MPLAYER" autoPlay onCanPlay={onPlay} src={source?.url?.[quality]} />

                {mode == 'playlist'
                    ?
                    <PlayList onHide={() => { setMode('full') }} list={playlist} />
                    :
                    null
                }
                {showMore
                    ?
                    <More
                        operator={operator}
                        setMode={setMode}
                        source={source}
                        onHide={() => { setShowMore(false) }}
                    />
                    :
                    null
                }
                <div ref={radinowPlayer} style={{ backgroundImage: `url(${source?.cover})` }} id='playermusicl' className={`player`}>
                    <div className='layer-backdrop'></div>
                    <div className='position-relative h-100' style={{ zIndex: 11 }}
                        onTouchEnd={(e) => {
                            if (e.changedTouches[0].clientY < window.innerHeight / 2) {
                                setBottomPlayer(0);
                                disableScrollBody();
                            } else {
                                setBottomPlayer(-endBottomPlayer);
                                resetScrollBody()
                            }
                        }}
                        onTouchMove={(e) => {
                            disableScrollBody();
                            if (e.changedTouches[0].clientY > 0 && endBottomPlayer >= e.changedTouches[0].clientY) {
                                setBottomPlayer(-e.changedTouches[0].clientY);
                                disableScrollBody();
                            }
                            if (radinowPlayer.current) {
                                radinowPlayer.current.style.bottom = -e.changedTouches[0].clientY + 'px';
                                if (e.changedTouches[0].clientY > (window.innerHeight - 45)) {
                                    closeclick();
                                    resetScrollBody()
                                }


                            }
                        }}>
                        <div id='PlayerHeaderMini' className='header-mini px-4'>
                            <Renger
                                player={player}
                                statusPlay={statusPlay}
                                random={random}
                                randomPlay={randomPlay}
                                repeatplaylist={repeatplaylist}
                                nextMusic={nextMusic}
                                repeatmusic={repeatmusic}
                                setStatusPlay={setStatusPlay}
                            />
                            <div className="content-mini-player">
                                <div className='d-flex mb-2 align-items-center'>
                                    <i onClick={() => { operator.onLike('like') }} id='heart' className={`${operator.activities?.statusLike ? 'fas' : 'far'}  ${operator.loading.like ? 'spinner-border' : 'fa-heart'}`} style={{ borderWidth: 2, margin: '0 0 0 20px' }}></i>
                                    <i onClick={() => {
                                        operator.onBookmark(source?.slug)
                                    }} className={`${source?.properties?.isBookmark ? 'fas' : 'far'} ${operator.loading.bookmark ? 'spinner-border' : 'fa-bookmark'}`} style={{ borderWidth: 2 }}></i>
                                    <div className='mx-3' onClick={() => {
                                        setBottomPlayer(0);
                                        disableScrollBody();
                                    }}>
                                        <p className='mb-0'>{source?.name}</p>
                                        <small>{source?.artistinfo?.userinfo?.name}</small>
                                    </div>
                                </div>
                                <div className='d-flex aling-items-center'>
                                    <div className='me-3' onClick={() => {
                                        if (player.current) {
                                            if (player.current.currentTime) {
                                                onTogglePlay();
                                                if (togglePlay) {
                                                    Pause()
                                                }
                                            }
                                        }
                                    }}>
                                        {!statusPlay ?
                                            <svg height="21" className='icon-play' fill='var(--black)' viewBox="0 0 18 21" width="21" xmlns="http://www.w3.org/2000/svg"><path d="M0.666016 1.86652V19.1332C0.666016 20.4498 2.11602 21.2498 3.23268 20.5332L16.7994 11.8998C17.8327 11.2498 17.8327 9.74985 16.7994 9.08318L3.23268 0.466517C2.11602 -0.250149 0.666016 0.549851 0.666016 1.86652Z"></path></svg>
                                            :
                                            <svg height="21" fill='var(--black)' viewBox="-45 0 327 327" width="21" xmlns="http://www.w3.org/2000/svg"><path d="m158 0h71c4.417969 0 8 3.582031 8 8v311c0 4.417969-3.582031 8-8 8h-71c-4.417969 0-8-3.582031-8-8v-311c0-4.417969 3.582031-8 8-8zm0 0"></path><path d="m8 0h71c4.417969 0 8 3.582031 8 8v311c0 4.417969-3.582031 8-8 8h-71c-4.417969 0-8-3.582031-8-8v-311c0-4.417969 3.582031-8 8-8zm0 0"></path></svg>}

                                    </div>
                                    <svg width="20" height="20" onClick={nextMusic} fill='var(--black)' className='icon-next' viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.499878C1.55 0.499878 2 0.949878 2 1.49988V11.4999C2 12.0499 1.55 12.4999 1 12.4999C0.45 12.4999 0 12.0499 0 11.4999V1.49988C0 0.949878 0.45 0.499878 1 0.499878ZM4.66 7.31988L10.43 11.3899C11.09 11.8599 12.01 11.3799 12.01 10.5699V2.42988C12.01 1.61988 11.1 1.14988 10.43 1.60988L4.66 5.67988C4.09 6.07988 4.09 6.91988 4.66 7.31988Z"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div id='PlayerHeader' className="header p-4 d-flex align-items-center justify-content-between">
                            <i onClick={() => { setMode('playlist') }} className='icon ti-list' style={{ zIndex: 13 }}></i>
                            <i className='icon ti-angle-down' onClick={() => {
                                setBottomPlayer(-endBottomPlayer);
                                resetScrollBody()
                            }}></i>
                        </div>
                    </div>
                    <div className='main' style={{ zIndex: 11 }}>
                        <Splide ref={slider}
                            onMove={(s) => {

                                setCurrentmusic(s.index);

                            }}
                            options={{
                                start: currentmusic,
                                direction: "rtl",
                                perPage: 1,
                                pagination: false,
                                perMove: 1,
                                gap: 35,
                                padding: "50px",
                                arrows: false,
                            }}>
                            {[...playlist]?.map((item, i) => {
                                return (
                                    <SplideSlide key={i}>
                                        <div className="artist-image">
                                            <img src={item?.cover} alt={`image-${i}`} id={"img-music-" + item?.slug} />
                                        </div>
                                    </SplideSlide>
                                )
                            })}
                        </Splide>

                        <div className='content px-4 '>
                            <div className='information-music d-flex align-items-center justify-content-between mb-4'>
                                <div className='name'>
                                    <h5 className='mb-2'>{source?.name}</h5>
                                    <small>{source?.artistinfo?.userinfo?.name}</small>
                                </div>

                                <div onClick={setShowMore} className='more'>
                                    <i className='far fa-ellipsis-v'></i>
                                </div>
                            </div>
                            <SliderPlayer
                                player={player}
                                statusPlay={statusPlay}
                                random={random}
                                randomPlay={randomPlay}
                                repeatplaylist={repeatplaylist}
                                nextMusic={nextMusic}
                                repeatmusic={repeatmusic}
                                setStatusPlay={setStatusPlay}
                            />

                            <div className='control-player d-flex align-items-center justify-content-around '>
                                <i onClick={() => {
                                    operator.onBookmark(source?.slug)
                                }} className={`${source?.properties?.isBookmark ? "fas" : "far"} ${operator.loading.bookmark ? 'spinner-border' : 'fa-bookmark'}`} style={{ borderWidth: 2 }}></i>
                                <svg width="25" height="25" onClick={previousMusic} fill='var(--black)' className='icon-prv' viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.499878C1.55 0.499878 2 0.949878 2 1.49988V11.4999C2 12.0499 1.55 12.4999 1 12.4999C0.45 12.4999 0 12.0499 0 11.4999V1.49988C0 0.949878 0.45 0.499878 1 0.499878ZM4.66 7.31988L10.43 11.3899C11.09 11.8599 12.01 11.3799 12.01 10.5699V2.42988C12.01 1.61988 11.1 1.14988 10.43 1.60988L4.66 5.67988C4.09 6.07988 4.09 6.91988 4.66 7.31988Z"></path></svg>
                                <div className=' icon-toggle' onClick={() => {
                                    if (player.current) {
                                        if (player.current.currentTime) {
                                            onTogglePlay();
                                            if (togglePlay) {
                                                Pause()
                                            }
                                        }
                                    }
                                }}>
                                    {!statusPlay ?
                                        <svg height="20" className='icon-play' style={{ marginLeft: 2 }} fill='var(--white)' viewBox="0 0 18 21" width="21" xmlns="http://www.w3.org/2000/svg"><path d="M0.666016 1.86652V19.1332C0.666016 20.4498 2.11602 21.2498 3.23268 20.5332L16.7994 11.8998C17.8327 11.2498 17.8327 9.74985 16.7994 9.08318L3.23268 0.466517C2.11602 -0.250149 0.666016 0.549851 0.666016 1.86652Z"></path></svg>
                                        :
                                        <svg height="20" fill='var(--white)' style={{ marginLeft: 2 }} viewBox="-45 0 327 327" width="21" xmlns="http://www.w3.org/2000/svg"><path d="m158 0h71c4.417969 0 8 3.582031 8 8v311c0 4.417969-3.582031 8-8 8h-71c-4.417969 0-8-3.582031-8-8v-311c0-4.417969 3.582031-8 8-8zm0 0"></path><path d="m8 0h71c4.417969 0 8 3.582031 8 8v311c0 4.417969-3.582031 8-8 8h-71c-4.417969 0-8-3.582031-8-8v-311c0-4.417969 3.582031-8 8-8zm0 0"></path></svg>}

                                </div>
                                <svg width="25" height="25" onClick={nextMusic} fill='var(--black)' className='icon-next' viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.499878C1.55 0.499878 2 0.949878 2 1.49988V11.4999C2 12.0499 1.55 12.4999 1 12.4999C0.45 12.4999 0 12.0499 0 11.4999V1.49988C0 0.949878 0.45 0.499878 1 0.499878ZM4.66 7.31988L10.43 11.3899C11.09 11.8599 12.01 11.3799 12.01 10.5699V2.42988C12.01 1.61988 11.1 1.14988 10.43 1.60988L4.66 5.67988C4.09 6.07988 4.09 6.91988 4.66 7.31988Z"></path></svg>
                                <i onClick={async () => {
                                    try {
                                        if (navigator.canShare) {
                                            await navigator.share({
                                                title: source?.name,
                                                text: 'آهنگ ' + source?.name + " با صدای " + source?.artistinfo?.userinfo?.name + " را در خراسان مدیا گوش کنید \n\n\n لینک آهنگ \n",
                                                url: "https://khorasanmedia.ir/music/" + source?.slug,
                                            })
                                        } else {
                                            if (window.AndroidInterface) {
                                                window.AndroidInterface.share('آهنگ ' + source?.name + " با صدای " + source?.artistinfo?.userinfo?.name + " را در خراسان مدیا گوش کنید \n\n\n لینک آهنگ \n https://khorasanmedia.ir/music/" + source?.slug);
                                            }
                                        }
                                    } catch (error) {

                                    }
                                }}
                                    className={`fal fa-share-alt`}></i>
                            </div>
                        </div>
                    </div>
                    <div className='footer' style={{ zIndex: 11 }}>

                        {getMobileOperatingSystem() === 'iOS' ?
                            null : (
                                <a style={{ cursor: 'pointer' }}
                                    onClick={async () => {
                                        let newmusicAll = [...musicAll];
                                        if (newmusicAll.filter(item => item.id != source.id)) {
                                            newmusicAll.push(source)
                                            localStorage.setItem('music', JSON.stringify(newmusicAll))
                                        }
                                        if (window.AndroidInterface) {
                                            if (window.AndroidInterface.downloadFile) {
                                                window.AndroidInterface.downloadFile(source?.url?.normal)
                                            }
                                        }
                                    }}
                                    className={'far fa-cloud-download'}
                                />
                            )}
                        <button type='button' onClick={() => { setQuality(quality === 'hq' ? "normal" : "hq") }} disabled={source?.url?.hq && String(source?.url?.hq).length ? false : true} style={{ background: 'transparent', border: 'none', outline: 'none' }}>
                            <svg style={{ cursor: source?.url?.hq && String(source?.url?.hq).length ? 'pointer' : 'unset' }} width="35" height="35" viewBox="0 0 28 15" fill={source?.url?.hq && String(source?.url?.hq).length ? 'var(--black)' : 'var(--gray)'} xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.99" fillRule="evenodd" clipRule="evenodd" d="M26.24 12.2219L27 14.4999H25L24.333 12.4999H18C16.343 12.4999 15 11.1609 15 9.49988V3.49988C15 1.84088 16.348 0.499878 18 0.499878H25C26.657 0.499878 28 1.83888 28 3.49988V9.49988C28 10.8899 27.179 11.7979 26.24 12.2219ZM2 0.499878H0V12.4999H2V7.49988H11V12.4999H13V0.499878H11V5.49988H2V0.499878ZM17 9.49988C17 10.0559 17.447 10.4999 18 10.4999H25C25.5 10.4999 26 10.0519 26 9.49988V3.49988C26 2.94388 25.553 2.49988 25 2.49988H18C17.45 2.49988 17 2.94788 17 3.49988V9.49988Z" />
                            </svg>
                        </button>
                        {
                            repeatplaylist ?
                                <div className='icon' onClick={() => repeatmusichandler()}>
                                    <svg width="25" height="25" viewBox="0 0 18 19" fill="var(--black)" xmlns="http://www.w3.org/2000/svg"><path className='active' d="M4.00094 4.49982H14.0009V6.28982C14.0009 6.73982 14.5409 6.95982 14.8509 6.63982L17.6409 3.84982C17.8409 3.64982 17.8409 3.33982 17.6409 3.13982L14.8509 0.349819C14.5409 0.0398186 14.0009 0.259819 14.0009 0.709819V2.49982H3.00094C2.45094 2.49982 2.00094 2.94982 2.00094 3.49982V7.49982C2.00094 8.04982 2.45094 8.49982 3.00094 8.49982C3.55094 8.49982 4.00094 8.04982 4.00094 7.49982V4.49982ZM14.0009 14.4998H4.00094V12.7098C4.00094 12.2598 3.46094 12.0398 3.15094 12.3598L0.360938 15.1498C0.160938 15.3498 0.160938 15.6598 0.360938 15.8598L3.15094 18.6498C3.46094 18.9598 4.00094 18.7398 4.00094 18.2898V16.4998H15.0009C15.5509 16.4998 16.0009 16.0498 16.0009 15.4998V11.4998C16.0009 10.9498 15.5509 10.4998 15.0009 10.4998C14.4509 10.4998 14.0009 10.9498 14.0009 11.4998V14.4998Z"></path></svg>
                                </div>

                                :
                                repeatmusic ?
                                    <div className='icon' onClick={() => nonrepeathandler()}>
                                        <svg width="25" height="25" viewBox="0 0 18 19" fill="var(--black)" xmlns="http://www.w3.org/2000/svg"><path className='active' d="M4.00094 4.49982H14.0009V6.28982C14.0009 6.73982 14.5409 6.95982 14.8509 6.63982L17.6409 3.84982C17.8409 3.64982 17.8409 3.33982 17.6409 3.13982L14.8509 0.349819C14.5409 0.0398186 14.0009 0.259819 14.0009 0.709819V2.49982H3.00094C2.45094 2.49982 2.00094 2.94982 2.00094 3.49982V7.49982C2.00094 8.04982 2.45094 8.49982 3.00094 8.49982C3.55094 8.49982 4.00094 8.04982 4.00094 7.49982V4.49982ZM14.0009 14.4998H4.00094V12.7098C4.00094 12.2598 3.46094 12.0398 3.15094 12.3598L0.360938 15.1498C0.160938 15.3498 0.160938 15.6598 0.360938 15.8598L3.15094 18.6498C3.46094 18.9598 4.00094 18.7398 4.00094 18.2898V16.4998H15.0009C15.5509 16.4998 16.0009 16.0498 16.0009 15.4998V11.4998C16.0009 10.9498 15.5509 10.4998 15.0009 10.4998C14.4509 10.4998 14.0009 10.9498 14.0009 11.4998V14.4998Z" fill="#8E8E8E"></path><path className='active' d="M9.20361 13H8.38623V7.58154L6.74707 8.18359V7.44531L9.07617 6.5708H9.20361V13Z"></path></svg>
                                    </div>
                                    :
                                    <div className='icon' onClick={() => repeatplaylisthandler()}>
                                        <svg width="25" height="25" viewBox="0 0 18 19" fill="var(--black)" xmlns="http://www.w3.org/2000/svg"><path d="M4.00094 4.49982H14.0009V6.28982C14.0009 6.73982 14.5409 6.95982 14.8509 6.63982L17.6409 3.84982C17.8409 3.64982 17.8409 3.33982 17.6409 3.13982L14.8509 0.349819C14.5409 0.0398186 14.0009 0.259819 14.0009 0.709819V2.49982H3.00094C2.45094 2.49982 2.00094 2.94982 2.00094 3.49982V7.49982C2.00094 8.04982 2.45094 8.49982 3.00094 8.49982C3.55094 8.49982 4.00094 8.04982 4.00094 7.49982V4.49982ZM14.0009 14.4998H4.00094V12.7098C4.00094 12.2598 3.46094 12.0398 3.15094 12.3598L0.360938 15.1498C0.160938 15.3498 0.160938 15.6598 0.360938 15.8598L3.15094 18.6498C3.46094 18.9598 4.00094 18.7398 4.00094 18.2898V16.4998H15.0009C15.5509 16.4998 16.0009 16.0498 16.0009 15.4998V11.4998C16.0009 10.9498 15.5509 10.4998 15.0009 10.4998C14.4509 10.4998 14.0009 10.9498 14.0009 11.4998V14.4998Z"></path></svg>
                                    </div>

                        }
                        <svg onClick={() => setRandom(!random)} width="23" height="23" viewBox="0 0 16 17" fill='var(--black)' xmlns="http://www.w3.org/2000/svg"><path className={random && 'active'} d="M6.5907 5.66988L2.1207 1.19988C1.7307 0.809878 1.1007 0.809878 0.710703 1.19988C0.320703 1.58988 0.320703 2.21988 0.710703 2.60988L5.1707 7.06988L6.5907 5.66988ZM11.3507 1.34988L12.5407 2.53988L0.700703 14.3799C0.310703 14.7699 0.310703 15.3999 0.700703 15.7899C1.0907 16.1799 1.7207 16.1799 2.1107 15.7899L13.9607 3.95988L15.1507 5.14988C15.4607 5.45988 16.0007 5.23988 16.0007 4.78988V0.999878C16.0007 0.719878 15.7807 0.499878 15.5007 0.499878H11.7107C11.2607 0.499878 11.0407 1.03988 11.3507 1.34988ZM10.8307 9.90988L9.4207 11.3199L12.5507 14.4499L11.3507 15.6499C11.0407 15.9599 11.2607 16.4999 11.7107 16.4999H15.5007C15.7807 16.4999 16.0007 16.2799 16.0007 15.9999V12.2099C16.0007 11.7599 15.4607 11.5399 15.1507 11.8599L13.9607 13.0499L10.8307 9.90988Z"></path></svg>
                    </div>
                </div>
            </> : null

    )
}