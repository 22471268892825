import React from 'react'
import { useBaseData } from '../../hooks'

export const Rules = () => {
    const { data, loading } = useBaseData()
    return (
        <div>
            <h3 className='text-center my-4'>قوانین و مقررات</h3>
            {loading ?
                <div style={{ width: "100%" }} className="d-flex justify-content-center align-items-center mt-4">
                    <div className="spinner-border spinner-border-sm" style={{ color: "var(--primary)" }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <div className='px-3' dangerouslySetInnerHTML={{ __html: data?.rulls }}></div>
            }
        </div>
    )
}
