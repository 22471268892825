import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
import { useAccount } from '../../hooks'
import { LoaderSingleLine } from '../../components/commons'

export const Account = () => {
  const { userData } = useAccount()
  return (
    <div className='container account'>
      <div className="d-flex justify-content-between align-items-center borderb py-3">
        {userData
          ?
          <div>
            <div className="name">{userData?.username}</div>
            <div className="lighttext">{userData?.name} </div>
            <div className="lighttext">{userData?.mobile}</div>
          </div>
          :
          <div className="col-4 mx-3">
            <LoaderSingleLine count={[1]} />
          </div>
        }
        <div className="circle"> {userData ? <img src={userData.profile} className='w-100 h-100' alt="" /> : ""}</div>
      </div>
      <Link to="/account/settings">
        <div className="d-flex item align-items-center mt-3">
          <i className='icon ti-settings'></i>
          <span>تنظیمات</span>
        </div>
      </Link>
      <Link to="/account/edit-profile">
        <div className="d-flex item align-items-center">
          <i className='icon ti-pencil'></i>
          <span>ویرایش اطلاعات</span>
        </div>
      </Link>
      {userData?.role === "client"
        ?
        <Link to='/account/upgrade'>
          <div className="d-flex item align-items-center">
            <i className='icon ti-pencil'></i>
            <span>درخواست ارتقا حساب کاربری</span>
          </div>
        </Link>
        :
        null
      }
    </div>
  )
}

export { EditProfile } from './edit-profile'
export { Settings } from './settings'
export { Upgrade } from './upgrade'