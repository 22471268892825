import React, { useEffect, useState } from 'react'

export const Renger = ({
    player,
    statusPlay,
    random,
    randomPlay,
    repeatplaylist,
    nextMusic,
    repeatmusic,
}) => {

    const [currentTime, setCurrentTime] = useState(0);

    const getAudioTimeString = (seconds) => {
        const h = parseInt(seconds / (60 * 60));
        const m = parseInt(seconds % (60 * 60) / 60);
        const s = parseInt(seconds % 60);
        let hours = "";
        if (h) {
            hours = (h < 10 ? '0' + h : h) + ':';
        }
        return (hours + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s));
    }
    const getProgress = () => {
        if (player.current?.duration) {
            return (currentTime * 100) / player.current?.duration
        }
        return 0;
    }
    useEffect(() => {
        let timeout = null;
        if (player.current) {
            timeout = setInterval(() => {
                if (player.current && !player.current.paused) {
                    setCurrentTime(player.current.currentTime)
                }
            }, 100)
        }
        if (!statusPlay) {
            clearInterval(timeout)
        }
        return () => {
            clearInterval(timeout)
        }
    }, [statusPlay])
    const onChangeCurrentTime = ({ target: { value } }) => {

        if (player.current) {
            let newCurrentTime = (value * player.current?.duration) / 100;
            setCurrentTime(newCurrentTime);
            player.current.currentTime = newCurrentTime;
        }

    }
    useEffect(() => {
        if (getAudioTimeString(currentTime) === getAudioTimeString(player.current?.duration)) {
            if (repeatmusic) {
                player.current.loop = true
                setCurrentTime(0)
            } else {
                if (random) {
                    randomPlay()
                } else {
                    if (repeatplaylist) {
                        nextMusic()
                        setCurrentTime(0)
                    }
                }
            }
        }
    }, [currentTime, repeatplaylist, repeatmusic, player.current?.duration])



    return (
        <div id='Renger'>
            <div style={{ width: getProgress(currentTime || 0) + "%" }}></div>
        </div>
    )

}