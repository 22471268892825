import { useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import './style.scss'

export function HeaderMusic({ children }) {

    const { pathname } = useLocation()
    const { id } = useParams();
    const Item = [
        {
            name: 'همه',
            id: 'music',
            href: '/music',
            hrefTow: '/music/all'
        },
        {
            name: 'برتر',
            id: 'superior',
            href: '/music/superior'
        },
        {
            name: 'آلبوم ها',
            id: 'albums',
            href: '/music/albums'
        },
        {
            name: 'خواننده ها',
            id: 'artists',
            href: '/music/artists'
        },
        {
            name: 'دسته بندی',
            id: 'categories',
            href: '/music/categories'
        },
        {
            name: 'پیشنهادی سایت',
            id: 'suggests',
            href: '/music/suggests',
        }
    ]

    return (
        <div className="headerMusic">
            <div className='tabitem'>
                <ul>
                    {Item.map((item, i) => {
                        return (
                            <Link to={item.href} key={i}>
                                <li className={pathname === item.href || pathname === item.hrefTow || pathname === item.href + '/' + id ? 'active' : ''}>
                                    {item.name}
                                </li>
                            </Link>
                        )
                    })}
                </ul>
            </div>
            {children}
        </div>
    )
}