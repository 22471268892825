
import { images } from '../../assets'
import { SVG } from '../../components/commons'
import { CategoryImage } from '../../components/views'
import './style.scss'

export const Category = () => {
    const ItemCategory = [
        {
            name: 'خواننده ها',
            pictuer: images.position,
            icon: <SVG.SvgSinger />,
            href: '/music/artists',
        },
        {
            name: 'کلیپ ها',
            pictuer: images.musicvideo,
            icon: <SVG.SvgClip />,
            href: '/music-videos',
        },
        {
            name: 'کنسرت ها',
            pictuer: images.theater,
            icon: <SVG.SvgConcert />,
            href: '/landing/concert',
        },
        {
            name: 'موسیقی ها',
            pictuer: images.music,
            icon: <SVG.SvgMusic />,
            href: '/music',
        },
        {
            name: 'فیلم ها',
            pictuer: images.film,
            icon: <SVG.SvgFilm />,
            href: '/landing/vip',
        },
        {
            name: 'تئاتروطنز',
            pictuer: images.comedy,
            icon: <SVG.SvgComedy />,
            href: '/landing/theater',
        }
    ]
    return (
        <div className='container'>
            <div className="CategoryBox">
                {Array.isArray(ItemCategory) && ItemCategory.map((item, index) => {
                    return (
                        <div className="sizeBox p-2" key={index}>
                            <CategoryImage
                                icon={item.icon}
                                image={item.pictuer}
                                label={item.name}
                                style={{ borderRadius: "10px" }}
                                to={item.href}
                            />
                        </div>
                    )
                })
                }
            </div>
        </div>
    )
}

