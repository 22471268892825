import { useState, useEffect, useRef } from 'react'
import { useRequest } from './api';
import { useNavigate } from 'react-router';
import { controlHeader } from '../components';



export const useSearch = () => {
    const input = useRef()
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState([]);
    const request = useRequest();
    const navigate = useNavigate();

    const onClear = () => {
        if (query || input?.current?.value) {
            setQuery(null)
            input.current.value = ""
        } else {
            navigate(-1)
        }
    }

    useEffect(controlHeader, []);

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (query?.length >= 2) {
                request.send({ url: `search?text=${query}`, method: "GET" }).then((response) => {
                    setSearch(response.data);
                })
            }
        }, 500);

        return () => {
            clearTimeout(timeout)
        }
    }, [query]);

    return {
        query, setQuery, onClear, search, navigate, input
    }


}

