import React from 'react'
import { Artist, InfinityList } from '../../../components'
import { Helmet } from 'react-helmet'

export const Artists = () => {
    return (
        <div className='singersMusic'>
        <Helmet>
          <title>خواننده ها</title>
        </Helmet>
        <InfinityList
          url="artists?"
          render={(item, i) => {
            return (
              <div className="col-sm-4 col-6 mb-3" key={i}>
                <Artist
                      to={`/artist/${item?.slug}`}
                      name={item?.userinfo?.name}
                      image={item?.userinfo?.profile}
                    />
              </div>
            )
          }}
        />

      </div>
    )
}
