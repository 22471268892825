import React, { Fragment } from 'react'
import "./style.scss"
import { Helmet } from 'react-helmet'
import { useMusicVideo } from '../../hooks'
import { Poster, Section, SliderBase, VerticalVideo } from '../../components/views'
export const MusicVideo = () => {
    const { video, loading, others, relateds } = useMusicVideo();
    return (
        <Fragment>

            {loading ?
                <div style={{ width: "100%" }} className="d-flex justify-content-center align-items-center mt-4">
                    <div className="spinner-border spinner-border-sm" style={{ color: "var(--primary)" }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> :
                <Fragment>
                    <Helmet>
                        <title>{`${video?.name}موزیک ویدئو | خراسان مدیا`}</title>
                    </Helmet>
                    <div className='single_clip'>
                        <Poster video={video} type='music-video' />
                        <div className='container'>
                            <div className='listVideo row'>
                                {others?.length ?

                                    <div className='my-4'>
                                        <Section title="سایر موزیک ویدئو ها">
                                            <SliderBase
                                                pagination={false}
                                                breakpoints={{
                                                    768: {
                                                        perPage: 3,
                                                    },
                                                    479: {
                                                        perPage: 2,
                                                    },
                                                    1024: {
                                                        perPage: 4
                                                    }
                                                }}
                                                prePage={4}
                                                data={others}
                                                renderItem={(item, i) => {
                                                    return (
                                                        <VerticalVideo
                                                            key={i}
                                                            cover={item?.cover}
                                                            to={'/music-videos/' + item?.slug}
                                                            artist_name={item?.artistinfo?.userinfo?.name}
                                                            toArtist={'/artist/' + item?.artistinfo?.slug}
                                                            release_year={item?.info?.release_year}
                                                            name_video={item?.name}
                                                        />
                                                    )
                                                }}
                                            />
                                        </Section>
                                    </div>
                                    : null

                                }
                                {relateds?.length ?

                                    <div className='my-4'>
                                        <Section title="پیشنهادی">
                                            <SliderBase
                                                pagination={false}
                                                breakpoints={{
                                                    768: {
                                                        perPage: 3,
                                                    },
                                                    479: {
                                                        perPage: 2,
                                                    },
                                                    1024: {
                                                        perPage: 4
                                                    }
                                                }}
                                                prePage={4}
                                                data={relateds}
                                                renderItem={(item, i) => {
                                                    return (
                                                        <VerticalVideo
                                                            key={i}
                                                            cover={item?.cover}
                                                            to={'/music-videos/' + item?.slug}
                                                            artist_name={item?.artistinfo?.userinfo?.name}
                                                            toArtist={'/artist/' + item?.artistinfo?.slug}
                                                            release_year={item?.info?.release_year}
                                                            name_video={item?.name}
                                                        />
                                                    )
                                                }}
                                            />
                                        </Section>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}

