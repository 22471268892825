import React from 'react'
import './style.scss';
import { Route, Routes } from 'react-router';
import { ArtistPage } from './artist';
import { ArtistMusics } from './musics';
import { ArtistVideos } from './videos';
import ArtistMusicVideos from './music-videos';
import ArtistAlbums from './albums';

export const Artist = () => {
    return (
        <Routes>
            <Route path='/:slug' element={<ArtistPage />} />
            <Route path='/musics/:slug' element={<ArtistMusics />} />
            <Route path='/music-videos/:slug' element={<ArtistMusicVideos />} />
            <Route path='/albums/:slug' element={<ArtistAlbums />} />
            <Route path='/videos/:slug/:category' element={<ArtistVideos />} />
        </Routes>

    )
}

