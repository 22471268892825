import React from 'react'
import { useCategory } from '../../../hooks'
import { CategoryImage, HorizontalImage, Loader, Loaderlinear, SpinnerLoading } from '../../../components';
import { useMusicContext } from '../../../contexts';

export const Category = () => {
    const { category, loading, navigate, handleSharing } = useCategory();
    const { playMusichandler, source, onTogglePlay, statusPlay } = useMusicContext();

    return (
        <div className='categories' style={{ paddingTop: '0' }}>
            {
                loading ?
                    <SpinnerLoading />
                    :
                    <>
                        <div className='contentSingle'>
                            <div className='imagecont' style={{ background: `url(${category?.category?.category_data?.cover})` }}>
                                <div className='goback' onClick={() => { navigate(-1) }} style={{ cursor: 'pointer' }}>
                                    <i className='far fa-arrow-left' />
                                </div>
                                <p className='namealbum'>{category?.category?.name}</p>
                                <div className="gradients"></div>
                                <div className='btngroup'>
                                    <button className='btnlike' style={{ background: 'transparent' }}>
                                        <i />
                                    </button>
                                    <button className='btnplay' onClick={() => {
                                        if (category?.musics?.data?.find?.((itm) => itm.slug === source?.slug)) {
                                            onTogglePlay()
                                        } else {
                                            playMusichandler(category?.musics?.data, 0)
                                        }
                                    }}>
                                        {
                                            category?.musics?.data?.find?.((itm) => itm.slug === source?.slug)
                                                &&
                                                statusPlay ?
                                                <>
                                                    <i className='far fa-pause' />
                                                    <span>توقف</span>
                                                </> :
                                                <>
                                                    <i className='far fa-play' />
                                                    <span>پخش</span>
                                                </>
                                        }
                                    </button>
                                    <button className='btnshare'>
                                        <i className='far fa-share-alt' onClick={handleSharing} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="my-4">
                            <div className="d-flex px-3" style={{ flexWrap: 'wrap' }}>
                                {category?.musics
                                    ?
                                    category?.musics?.data?.map((item, i) => {
                                        return (
                                            <div className="col-12 my-2" key={i}>
                                                <HorizontalImage
                                                    musicObject={item}
                                                    playlist={category?.musics?.data}
                                                    cover={item?.cover}
                                                    artist_name={item?.artistinfo?.userinfo?.name}
                                                    artist_slug={item?.artistinfo?.slug}
                                                    track_name={item?.name}
                                                    number={i + 1}
                                                    slug={item.slug}
                                                    duration={item?.duration} />
                                            </div>
                                        )
                                    })
                                    :
                                    <Loaderlinear />
                                }
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-4 px-4">
                                <p className='sectiontitle'>دسته بندی ها</p>
                            </div>
                            {category?.others
                                ?
                                <div className="d-flex px-4" style={{ flexWrap: 'wrap' }}>
                                    {category?.others.map((item, i) => {
                                        return (
                                            <div className="sizeBox mb-2 p-2" key={i}>
                                                <CategoryImage
                                                    image={item?.category_data?.cover}
                                                    label={item.name}
                                                    style={{ borderRadius: "10px" }}
                                                    to={`/music/categories/${item.slug}`}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className="d-flex px-1" style={{ flexWrap: "wrap" }}>
                                    <Loader className="loaderEight p-2" count={12} />
                                </div>
                            }
                        </div>
                    </>
            }
        </div>
    )
}

