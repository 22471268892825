import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import {
  ThemeContext,
  AppContext,
  HomeContext,
  MusicContext,
  VideoContext,
} from "./contexts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/style/global.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ThemeContext>
      <AppContext>
        <HomeContext>
          <VideoContext>
            <MusicContext>
              <Routes />
            </MusicContext>
          </VideoContext>
        </HomeContext>
        <ToastContainer position="bottom-center" />
      </AppContext>
    </ThemeContext>
  </BrowserRouter>
);
