import { useContext, createContext } from 'react'
import { useHome } from '../hooks';

const Context = createContext();

export const HomeContext = ({ children }) => {

    const home = useHome();

    return (
        <Context.Provider value={{
            ...home,
        }}>{children}</Context.Provider>
    )
}

export const useHomeContext = () => useContext(Context);
