import React from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../../assets/utils'

export const Sidebar = ({ container, theme, sidebar, onClose, onEndSidebar, onArrowSidebar }) => {
  const deepLink = (url) => {
    if (window.AndroidInterface) {
      window.AndroidInterface.deepLink(url)
    } else {
      window.location.replace(url);
    }
  }
  return (
    <div className='sidebarcontent' onTouchEnd={onEndSidebar} onTouchMove={onArrowSidebar} onClick={onClose} ref={container}>
      <div className="sidebar" ref={sidebar}>
        <div>
          <div className="d-flex justify-content-center my-3">
            {theme === 'light' ?
              <img width={155} height={55} alt="logo" src={images.logolight} />
              :
              <img width={155} height={55} alt="logo" src={images.logodark} />
            }
          </div>
          <Link to="/">
            <div className="item bordertop pt-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                  <i className='icon ti-home' />
                  <span>خانه</span>
                </div>
                <i className='icon ti-angle-left' />
              </div>
            </div>
          </Link>
          <Link to="/music">
            <div className="item">
              <div className="d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                  <i className='icon ti-music' />
                  <span>آهنگ</span>
                </div>
                <i className='icon ti-angle-left' />
              </div>
            </div>
          </Link>
          <Link to="/music-videos">
            <div className="item">
              <div className="d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                  <i className='icon ti-video-camera' />
                  <span>موزیک ویدئو</span>
                </div>
                <i className='icon ti-angle-left' />
              </div>
            </div>
          </Link>
          <Link to="/landing/vip">
            <div className="item">
              <div className="d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                  <i className='icon ti-video-clapper' />
                  <span>اختصاصی خراسان مدیا</span>
                </div>
                <i className='icon ti-angle-left' />
              </div>
            </div>
          </Link>
          <Link to="/landing/concert">
            <div className="item">
              <div className="d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                  <i className="far fa-guitar mx-1"></i>
                  <span>کنسرت</span>
                </div>
                <i className='icon ti-angle-left' />
              </div>
            </div>
          </Link>
          <Link to="/account">
            <div className="item  pt-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                  <i className='icon ti-user' />
                  <span>پروفایل</span>
                </div>
                <i className='icon ti-angle-left' />
              </div>
            </div>
          </Link>
          <Link to="/rules">
            <div className="item bordertop pt-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                  <i className='icon ti-info' />
                  <span>قوانین و مقررات</span>
                </div>
                <i className='icon ti-angle-left' />
              </div>
            </div>
          </Link>
          <a onClick={() => { deepLink("mailto:admin@khorasanmedia.ir") }}>
            <div className="item bordertop pt-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                  <i className='icon ti-email' />
                  <span>ایمیل به پشتیبانی</span>
                </div>
                <i className='icon ti-angle-left' />
              </div>
            </div>
          </a>
        </div>
        <div className='d-flex aligm-item-center mt-5 justify-content-center'>
          <a onClick={() => deepLink("https://rubika.ir/Khorasanmedia1")}>
            <div>
              <span className='mx-2'>
                <img src={images.logoRubika} width={35} height={35} alt='logoRubika' />
              </span>
            </div>
          </a>
          <a onClick={() => deepLink("https://t.me/Khorasanmedia1")}>
            <div>
              <span className='mx-2'>
                <img src={images.logoTel} width={35} height={35} alt='logoTel' />
              </span>
            </div>
          </a>
          <a onClick={() => deepLink("https://instagram.com/khorasanmedia1?igshid=ZDdkNTZiNTM=")}>
            <div>
              <span className='mx-2'>
                <img src={images.logoInsta} width={35} height={35} alt='logoInsta' />
              </span>
            </div>
          </a>
          <a onClick={() => deepLink("tel:+989936437908")}>
            <div>
              <span className='mx-2'>
                <img src={images.logoChat} width={35} height={35} alt='logoChat' />
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}
