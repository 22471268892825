import { useEffect, useState } from "react";
import { useRequest } from "./api";


export const useHome = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null);
    const Request = useRequest();

    const getData = () => {
        setLoading(true)
        Request.send({ url: 'home', method: "GET" }).then((response) => {
            setLoading(false)
            if (response) {
                if (response.status === 200) {
                    setData(response.data);
                }
            }
        });
    }

    useEffect(() => {
        getData()
        return () => {
            setData(null);
        }
    }, []);

    return {
        data,
        loading
    }
}


export const useBaseData = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null);
    const Request = useRequest();

    const getData = () => {
        setLoading(true)
        Request.send({ url: 'base', method: "GET" }).then((response) => {
            setLoading(false)
            if (response) {
                if (response.status === 200) {
                    setData(response.data);
                }
            }
        });
    }

    useEffect(() => {
        getData()
        return () => {
            setData(null);
        }
    }, []);

    return {
        data,
        loading
    }

}