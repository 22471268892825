import React from 'react'
import { Header } from './header'
import { Footer } from './footer'
import { Sidebar } from './sidebar'
import { useLayout } from '../../hooks/layout'
const Layout = ({ children }) => {
    const layout = useLayout();
    return (
        <>

            <div className='sidebarslide' onTouchEnd={layout.onEndSidebar} onTouchMove={layout.onArrowSidebar}></div>
            <Header {...layout} />
            <Sidebar {...layout} />
            <main className='main-content'>
                {children}
            </main>
            <Footer />
        </>
    )
}

export default Layout
export { HeaderMusic } from './header-music'
export { controlHeader } from './header'