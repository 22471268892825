import React from 'react'
import { useMusicArtist } from '../../hooks'
import { HorizontalImage2, LoaderSingleLine, SpinnerLoading } from '../../components'

export const ArtistMusics = () => {
    const { musics, loading } = useMusicArtist()

    return (loading
        ?
        (<SpinnerLoading />)
        :

        <div className='MusicList'>
            <div className="container">
                <h4 className='px-2 mb-4'>همه آهنگ ها</h4>
                <div className="headerMyMusic d-flex align-items-center w-100 py-2">
                    <span className='text-center'>
                        نام موزیک
                    </span>
                    <span className='text-center d-none d-md-flex'>
                        نام خواننده
                    </span>
                    <span className='text-center'>
                        <i className="icon ti-time"></i>
                    </span>
                </div>
                {musics?.data
                    ?
                    musics?.data?.map((item, i) => {
                        return (
                            <HorizontalImage2
                                cover={item?.cover}
                                duration={item?.duration}
                                artist_name={item?.artistinfo?.userinfo?.name}
                                artist_slug={item?.artistinfo?.slug}
                                track_name={item.name}
                                number={i + 1}
                                music_slug={item.slug}
                                playlist={musics?.data}
                                musicObject={item}
                            />
                        )
                    })
                    :
                    <LoaderSingleLine count={[1, 2, 3, 4, 5]} />
                }
            </div>
        </div>
    )
}

