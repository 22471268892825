import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useVideoContext } from './video';
import { useAuth } from '../hooks';
import { MusicPlayer } from '../components';


const Context = createContext();

export function MusicContext({ children }) {
    const [musicPlayer, setMusicPlayer] = useState();
    const [playlistshow, setPlaylistshow] = useState(false)
    const [playlist, setPlaylist] = useState([]);
    const [quality, setQuality] = useState('normal');
    const [currentmusic, setCurrentmusic] = useState(0)
    const [source, setSource] = useState()
    const [repeatmusic, setRepeatmusic] = useState(false)
    const [repeatplaylist, setRepeatplaylist] = useState(true)
    const [statusPlay, setStatusPlay] = useState(false)
    const [random, setRandom] = useState(false)
    const { user } = useAuth();
    const player = useRef();
    const navigate = useNavigate();
    const { setSource: setVideoSource, Pause: PauseVideo } = useVideoContext();

    const playMusichandler = (array, key) => {
        if (user) {
            setVideoSource(null);
            PauseVideo();
            setPlaylist(array);
            setSource(array[key])
            setCurrentmusic(key);
            setMusicPlayer(true);
onPlay()
        } else {
            navigate('/account')
        }
    }
    useEffect(() => {
        if (!user) {
            onPause();
            setMusicPlayer(false);
            setSource(null)
            setPlaylistshow(false)
        }
    }, [user])

    const nextMusic = () => {
        if (currentmusic === (playlist.length - 1)) {
            setCurrentmusic(0)
            if (repeatplaylist) {
                setSource(playlist[0])
            }
        }
        else {
            setCurrentmusic(currentmusic + 1)
            setSource(playlist[currentmusic + 1])
        }

    }

    const previousMusic = () => {
        if (currentmusic === 0) {
            setCurrentmusic(playlist.length - 1)
            setSource(playlist[playlist.length - 1])
        } else {
            setCurrentmusic(currentmusic - 1)
            setSource(playlist[currentmusic - 1])
        }
    }
    const randomPlay = () => {
        const randomnumb = Math.floor(Math.random() * (playlist.length + 1))
        setCurrentmusic(randomnumb)
        setSource(playlist[randomnumb])

    }
    const onPlay = () => {
        setVideoSource(null);
        PauseVideo();
        if (player.current) {
            player.current.play()
        }
        setStatusPlay(true)

    }
    const onPause = () => {
        if (player.current) {
            player.current.pause()
        }
        setStatusPlay(false)
    }
    const onTogglePlay = () => {
        if (user) {
            if (player.current) {
                if (player.current.paused) {
                    onPlay()
                } else {
                    onPause()
                }
            }
        } else {
            navigate('/account')
        }
    }


    return (
        <Context.Provider value={{
            musicPlayer,
            setMusicPlayer,
            source,
            setSource,
            playlistshow,
            setPlaylistshow,
            playlist,
            setPlaylist,
            currentmusic,
            setCurrentmusic,
            playMusichandler,
            nextMusic,
            previousMusic,
            repeatmusic,
            setRepeatmusic,
            repeatplaylist,
            setRepeatplaylist,
            statusPlay,
            setStatusPlay,
            player,
            onPlay,
            onPause,
            onTogglePlay,
            randomPlay,
            random,
            quality,
            setQuality,
            setRandom,
        }}>
            {children}
            <MusicPlayer />
        </Context.Provider>
    )
}

export const useMusicContext = () => useContext(Context);
