import moment from "moment-jalaali";

export const images = {
  logolight: require("../images/logolight.png"),
  logodark: require("../images/logodark.png"),
  bazar: require("../images/bazar.png"),
  dirct: require("../images/dirct.PNG"),
  categorysinger: require("../images/position.jpg"),
  categoryclip: require("../images/musicvideo.jpg"),
  categoryconcert: require("../images/theater.jpg"),
  categorymusic: require("../images/music.jpg"),
  categorytel: require("../images/other.jpg"),
  categoryfilm: require("../images/film.jpg"),
  categoryhumor: require("../images/comedy.jpg"),
  categorymute: require("../images/speechless.jpg"),
  logoTel: require("../images/telegram.png"),
  logoInsta: require("../images/instagram.png"),
  logoChat: require("../images/chat.png"),
  logoRubika: require("../images/rubika.png"),
  default: require("../images/Capture.PNG"),
  alboms: require("../images/Alboms.jpg"),
  position: require('../images/position.jpg'),
  theater: require('../images/theater.jpg'),
  musicvideo: require('../images/musicvideo.jpg'),
  music: require('../images/music.jpg'),
  film: require('../images/film.jpg'),
  comedy: require('../images/comedy.jpg'),
  Musics: require('../images/Musics.jpg'),
  news: require('../images/news.jpg'),
  Category: require('../images/Category.jpg'),
  Random: require('../images/Random.jpg'),
  Alboms: require('../images/Alboms.jpg'),

};

export const getAllTimesMusics = (list = []) => {
  let duration = list.reduce((acc, t) => {
    let time = String(t.duration).split(":");
    time = time.length > 2 ? t.duration : "00:" + t.duration;
    return acc.add(moment.duration(time));
  }, moment.duration());
  return duration.hours() + ":" + duration.minutes() + ":" + duration.seconds();
};


export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export const BaseUrlAPI = "https://api.khorasanmedia.ir/api/";
