import { useEffect, useState } from "react";
import { useRequest } from "./api";
import { useNavigate, useParams } from "react-router";
import { controlHeader } from "../components";

export const useMusic = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { send } = useRequest();

  useEffect(() => {
    setLoading(true);
    send({ url: `musics`, method: "GET" }).then((response) => {
      if (response.status === 200) {
        setData(response.data);
        setLoading(false);
      }
    });
  }, []);

  return {
    data,
    loading,
  };
};

export const useAlbum = () => {
  const { slug } = useParams();
  const [album, setAlbum] = useState(null);
  const [loading, setLoading] = useState(false);
  const { send } = useRequest();
  const navigate = useNavigate();

  const getAlbum = async () => {
    setLoading(true);
    await send({ url: `musics/album/${slug}`, method: "GET" }).then(
      (response) => {
        if (response.status === 200) {
          setAlbum(response.data);
          setLoading(false);
        }
      }
    );
  };
  const handleSharing = async () => {
    try {
      if (navigator.canShare) {
        await navigator.share({
          title: album?.name,
          text:
            "آلبوم " +
            album?.name +
            " با صدای " +
            album?.artistinfo?.userinfo?.name +
            " را در خراسان مدیا گوش کنید \n\n\n لینک آلبوم \n",
          url: "https://khorasanmedia.ir/music/albums/" + album?.slug,
        });
      } else {
        if (window.AndroidInterface) {
          window.AndroidInterface.share(
            "آلبوم " +
            album?.name +
            " با صدای " +
            album?.artistinfo?.userinfo?.name +
            " را در خراسان مدیا گوش کنید \n\n\n لینک آلبوم \n" +
            "https://khorasanmedia.ir/music/albums/" +
            album?.slug
          );
        }
      }
    } catch (error) {

    }
  };

  useEffect(controlHeader, []);

  useEffect(() => {
    getAlbum();
  }, [slug]);

  return { loading, album, navigate, handleSharing, setAlbum };
};

export const useSuggests = () => {
  const [suggests, setSuggests] = useState([]);
  const [loading, setLoading] = useState(false);
  const { send } = useRequest();
  const getSuggests = async () => {
    setLoading(true);
    await send({ url: `musics/all-suggests`, method: "GET" }).then(
      (response) => {
        if (response.status === 200) {
          setSuggests(response.data);
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    getSuggests();
  }, []);

  return { suggests, loading };
};

export const useCategory = () => {
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const { send } = useRequest();
  const { slug } = useParams();
  const navigate = useNavigate();

  const handleSharing = async () => {
    try {
      if (navigator.canShare) {
        await navigator.share({
          title: category?.category?.name,
          text:
            "دسته بندی " +
            category?.category?.name +
            " را در خراسان مدیا گوش کنید \n\n\n لینک دسته بندی  \n",
          url:
            "https://khorasanmedia.ir/music/categories/" +
            category?.category?.slug,
        });
      } else {
        if (window.AndroidInterface) {
          window.AndroidInterface.share(
            "دسته بندی " +
            category?.category?.name +
            " را در خراسان مدیا گوش کنید \n\n\n لینک دسته بندی \n" +
            "https://khorasanmedia.ir//music/categories/" +
            category?.category?.slug
          );
        }
      }
    } catch (error) {

    }
  };

  useEffect(controlHeader, []);
  useEffect(() => {
    const getCategory = async () => {
      setLoading(true);
      await send({ url: `musics/category/${slug}`, method: "GET" }).then(
        (response) => {
          if (response.status === 200) {
            setCategory(response.data);
            setLoading(false);
          }
        }
      );
    };
    getCategory();
  }, [slug]);

  return { category, loading, navigate, handleSharing };
};
