import React from 'react'
import { CategoryImage, InfinityList } from '../../../components'
import { Helmet } from 'react-helmet'

export const Categories = () => {
  return (
    <div className='categories'>
      <Helmet>
        <title>دسته بندی ها</title>
      </Helmet>
      <InfinityList
        url="musics/categories?"
        render={(item, i) => {
          return (
            <div className="sizeBox mb-4 px-1" key={i}>
              <CategoryImage

                image={item?.category_data?.cover}
                label={item.name}
                style={{ borderRadius: "10px" }}
                to={`/music/categories/${item.slug}`}
              />
            </div>
          )
        }}
      />

    </div>
  )
}

export { Category } from './category';