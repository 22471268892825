import { Link } from 'react-router-dom';
import { images } from '../../../assets/utils';
import style from './style.module.scss';

export const Artist = ({ name, image, to }) => {


    return (
        <Link to={to}>
            <div className={style.box_artist} >
                <div className={style.image}>
                    <img
                        onError={({ target }) => { target.src = images.default }}
                        src={image || images.default}
                        alt={name}
                    />
                </div>
                <div className={style.nameArtist + ' d-flex justify-content-center aligam-center'}>
                    <h4>{name}</h4>
                </div>
            </div>
        </Link>
    )
}
