import'./style.scss'

export const SpinnerLoading = () => {
    return (
        <div className='loading'>
            <span className='spinnerLoading'></span>
        </div>

    )
}

