import { useContext, createContext, useState } from 'react'

const Context = createContext();

export const AppContext = ({ children }) => {


    const userData = localStorage.getItem('user');
    const [user, setUser] = useState(JSON.parse(userData) || null)


    const login = (newData) => {
        setUser({ ...user, ...newData });
        localStorage.setItem('user', JSON.stringify({ ...user, ...newData }))
    }

    const logout = () => {
        localStorage.removeItem('user');
        setUser(null)
    }

    return (
        <Context.Provider value={{
            user,
            login,
            logout
        }}>{children}</Context.Provider>
    )
}

export const useApp = () => useContext(Context);
