import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
import { useApp } from '../../contexts';

export const Library = () => {
    const { user } = useApp();

    const ItemArtist = [
        { url: '/library/upload', name: 'موسیقی های من', icon: 'icon ti-music', state: '' },
        { url: '/library/uploadfile', name: 'آپلود فایل', icon: 'far fa-cloud-upload-alt', state: '' },
    ]

    const ItemClient = {
        favorite: [
            { url: '/library/liked', name: 'آهنگ ها', icon: 'icon ti-music', state: 'music' },
            { url: '/library/liked', name: 'موزیک ویدئو', icon: 'icon ti-video-camera', state: 'video' },
            { url: '/library/liked', name: 'فیلم های برگزیده', icon: 'icon ti-video-clapper', state: 'movie' },
        ],
        saved: [
            { url: '/library/bookmark', name: 'آهنگ ها', icon: 'icon ti-music', state: 'music' },
            { url: '/library/bookmark', name: 'موزیک ویدئو', icon: 'icon ti-video-camera', state: 'video' },
            { url: '/library/bookmark', name: 'فیلم های ذخیره شده', icon: 'icon ti-video-clapper', state: 'movie' },
        ]
    }

    return (
        <div className=' libarary-page container'>
            {user?.role === 'artist'
                ?
                <div className="borderb pb-3 mt-3">
                    <p className='lighttext'>فعالیت های من</p>
                    {Array.isArray(ItemArtist) && ItemArtist.map((x, i) => {
                        return (
                            <Link key={i} to={x.url} state={{ type: x.state }}>
                                <div className="d-flex item">
                                    <i className={x.icon}></i>
                                    <span>{x.name}</span>
                                </div>
                            </Link>
                        )
                    })}
                </div>
                :
                null
            }
            <div className="borderb pb-3 mt-3">
                <p className='lighttext'>علاقمندی ها</p>
                {Array.isArray(ItemClient.favorite) && ItemClient.favorite.map((x, i) => {
                    return (
                        <Link key={i} to={x.url} state={{ type: x.state }}>
                            <div className="d-flex item">
                                <i className={x.icon}></i>
                                <span>{x.name}</span>
                            </div>
                        </Link>
                    )
                })}
            </div>
            <div className="borderb mt-3">
                <p className='lighttext'>ذخیره شده ها</p>
                {Array.isArray(ItemClient.saved) && ItemClient.saved.map((x, i) => {
                    return (
                        <Link key={i} to={x.url} state={{ type: x.state }}>
                            <div className="d-flex item">
                                <i className={x.icon}></i>
                                <span>{x.name}</span>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}