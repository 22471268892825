import React, { useEffect } from 'react'
import { InfinityList, VerticalVideo } from '../../components'
import { useParams } from 'react-router'

export const ArtistVideos = () => {

  const { slug, category } = useParams()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [slug, category])

  return (

    <div className='videos'>
      <div className="container">

        <div className="content p-0">
          <InfinityList
            render={(video, i) => {
              return (
                <div className='sizeBox mb-2' key={i}>
                  <VerticalVideo
                    cover={video?.cover}
                    to={'/video/' + video?.slug}
                    artist_name={video?.artistinfo?.userinfo?.name}
                    release_year={video?.info?.release_year}
                    toArtist={'/artist/' + video?.artistinfo?.slug}
                    name_video={video?.name}
                  />
                </div>
              )
            }
            }
            url={`artists/videos/${slug}/${category}?`}
          />
        </div>
      </div>
    </div>

  )
}

