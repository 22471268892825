import React, { Fragment } from 'react'
import Form from '../../components/form';
import { useEditProfile } from '../../hooks';


export const EditProfile = () => {
  const { edit, userData, setUser, user } = useEditProfile()
  return (
    <div className='container'>
      <Form submit={edit}>
        <input type="hidden" name="_method" value="PUT" />
        <div className="d-flex align-items-center justify-content-center">
          <div className="circle position-relative my-4">
            <input type="file" onChange={({ target: { files } }) => {
              if (files && files.length) {
                setUser({ ...userData, profile: URL.createObjectURL(files[0]) })
              } else {
                setUser({ ...userData, profile: user.profile })
              }
            }} name='profile' accept='image/*' style={{ opacity: 0, position: "absolute", left: 0, top: 0, width: "100%", height: "100%", zIndex: 1 }} />
            {userData ? <img src={userData.profile} className='w-100 h-100' alt="" /> : ""}</div>
        </div>
        <span>نام و نام خانوادگی</span>
        <div className="input my-2">
          <input
            dir="rtl"
            type="text"
            name='name'
            defaultValue={userData?.name}
          />
        </div>
        <span>نام کاربری</span>
        <div className="input my-2">
          <input
            dir="rtl"
            type="text"
            name='username'
            defaultValue={userData?.username}
          />
        </div>
        {user.role === 'artist' ?
          (
            <Fragment>
              <span>بیوگرافی</span>
              <div className="input my-2">
                <input
                  dir="rtl"
                  type="text"
                  name='info[bio]'
                  defaultValue={userData?.info?.bio}
                />
              </div>
            </Fragment>
          )
          : null}
        <button type='submit' className='editbtn mt-3'>ویرایش</button>
      </Form>
    </div>
  )
}
