import { useEffect, useState } from "react";
import { Form } from "../../../components";
import { useUploadfile } from "../../../hooks";
import '../style.scss'

const useFileManager = () => {

  const [result, setResult] = useState()

  const getDuration = ({ file, type }) => {
    const tpHHMMSS = (time) => {
      let sec_num = parseInt(time, 10); // don't forget the second param
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return hours + ':' + minutes + ':' + seconds;
    }
    var item = window.document.createElement(type);
    item.preload = 'metadata';
    item.onloadedmetadata = function () {
      window.URL.revokeObjectURL(item.src);
      setResult(tpHHMMSS(item.duration));
    }
    item.src = URL.createObjectURL(file);;

  }

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = window.document.createElement("canvas");
      const video = window.document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve({ file: blobToFile(dataURItoBlob(canvas.toDataURL("image/png")), 'image-' + Math.random() * 1000000), url: canvas.toDataURL("image/png") });
      };
    });
  };
  const blobToFile = (theBlob, fileName) => {
    let file = new File([theBlob], fileName + ".jpg", { type: "image/jpeg", lastModified: new Date().getTime() });
    return file;
  }
  return {
    result,
    getDuration,
    generateVideoThumbnail
  }
}

const Input = ({ label, type, name, id, placeholder, className }) => {
  return (
    <div className={'col-md-4 col-sm-6 col-12 p-1 ' + className}>
      <div>
        <label htmlFor={id}>{label}</label>
        <input
          className='input'
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          style={{ direction: 'rtl' }}
        />
      </div>
    </div>
  )
}

export const Uploadfile = () => {
  const { data, preview, setPreview, postData, Slug, percent } = useUploadfile();
  const { getDuration, result } = useFileManager();
  const [duration, setDuration] = useState('');

  useEffect(() => {
    setDuration(result)
  }, [result])

  return (
    <div>
      <Form
        submit={postData}
        className='uplodFile'
        loading={`<div class="d-flex align-items-center justify-content-center h-100">${percent}%</div>`}
      >
        <input type="hidden" name="duration" value={duration} />
        <input type="hidden" name="slug" value={Slug} />
        <Input
          name='name'
          label='نام موزیک'
          placeholder='نام موزیک را وارد کنید'
          type='text'
          id='name'
        />
        <div className='col-md-4 col-sm-6 col-12 p-1'>
          <div>
            <label htmlFor=''>انتخاب دسته بندی</label>
            <select name="category_id">
              <option disabled>انتخاب کنید</option>
              {Array.isArray(data) && data?.map((item, i) => {
                return (
                  <option key={i} value={item?.id}>{item?.name}</option>
                )
              })
              }
            </select>
          </div>
        </div>
        <div className='col-md-4 col-sm-6 col-12 p-1'>
          <div>
            <label htmlFor='type-media'> نوع فایل </label>
            <select name="type" id="type-media">
              <option disabled>انتخاب کنید</option>
              <option value='music'>موزیک</option>
              <option value='video'>موزیک ویدئو</option>
            </select>
          </div>
        </div>
        <Input
          label='سال تولید'
          name='info[release_year]'
          id='release_year'
          placeholder='سال تولید را وارد کنید'
          type='number'
        />
        <Input
          label='نام نویسنده'
          name='info[songwriter]'
          id='songwriter'
          placeholder='نام نویسنده را وارد کنید'
          type='text'
        />
        <Input
          label='نام آهنگ ساز'
          name='info[composer]'
          id='composer'
          placeholder='نام آهنگ ساز را وارد کنید'
          type='text'
        />
        <Input
          label='نام تنظیم کننده'
          name='info[arranger]'
          id='arranger'
          placeholder='نام تنظیم کننده را وارد کنید'
          type='text'
        />

        <div className='col-12 p-1 '>
          <div>
            <label htmlFor='text-music'>{` متن موزیک (اختیاری)`}</label>
            <textarea
              className='input'
              name='text'
              id='text-music'
              placeholder={`متن موزیک را وارد نمایید (اگه می خوای تو گوگل بالا بیاد متن بنویس :)`}
              style={{ direction: 'rtl', height: 100 }}
            />
          </div>
        </div>
        <div className='col-sm-6 col-12 p-1'>
          <div>
            <label htmlFor='file-normal'>انتخاب فایل</label>
            <div className='file'>
              <p id="name-file">فایل مورد نظر را انتخاب کنید</p>
              <input
                type="file"
                name="urls[normal]"
                onChange={({ target: { files } }) => {
                  const name = window.document.getElementById('name-file');
                  if (files.length) {
                    getDuration({ file: files[0], type: "audio" });
                    name.innerHTML = files[0].name;
                  } else {
                    name.innerHTML = 'فایل مورد نظر را انتخاب کنید';
                  }
                }}
                accept="audio/mp3"
                id="file-normal"
              />
            </div>
          </div>
        </div>
        <div className='col-sm-6 col-12 p-1'>
          <div>
            <label htmlFor='file-cover'>{'انتخاب تصویر فایل (حداکثر 10Mb)'}</label>
            <div className='file'>

              {preview
                ?
                <img
                  className='image'
                  src={URL.createObjectURL(preview)}
                  alt="preview"
                  priority
                />
                :
                <p>تصویر مورد نظر را انتخاب کنید</p>
              }
              <input
                onChange={(e) => setPreview(e.target.files[0])}
                type="file"
                name="cover"
                id="file-cover"
              />
            </div>
          </div>
        </div>
        <div className='submit'>
          <button type="submit">ثبت</button>
        </div>
      </Form>
    </div >
  )
}
