import { useRef } from "react";
import { useTheme } from "../contexts/theme";

export const useLayout = () => {
  const container = useRef();
  const sidebar = useRef();
  const { theme } = useTheme();
  let arrowsMove = [];
  const onClose = () => {
    container.current.style.marginRight = "-100%";
    sidebar.current.style.marginRight = "-70%";
  };

  const onOpen = () => {
    container.current.style.marginRight = "0%";
    sidebar.current.style.marginRight = "0%";
  };

  const onEndSidebar = () => {
    if (arrowsMove[arrowsMove.length - 1] > arrowsMove[arrowsMove.length - 2]) {
      onClose();
    } else {
      onOpen();
    }
  };

  const onArrowSidebar = (e) => {
    container.current.style.marginRight = "0%";
    let perscent = (e.changedTouches[0].clientX / window.innerWidth) * 100;
    sidebar.current.style.marginRight = `-${(perscent * 70) / 100}%`;
    if (arrowsMove.length > 3) {
      arrowsMove = [];
      arrowsMove.push(e.changedTouches[0].clientX);
    } else {
      arrowsMove.push(e.changedTouches[0].clientX);
    }
  };

  return {
    container,
    sidebar,
    onClose,
    onOpen,
    onEndSidebar,
    theme,
    onArrowSidebar,
  };
};
