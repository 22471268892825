import { useEffect, useState } from "react";
import { useRequest } from ".";
import { useParams } from "react-router";
import { controlHeader } from "../components";

export const useMusicVideo = () => {
  const [video, setVideo] = useState();
  const [others, setOthers] = useState([]);
  const [relateds, setRelateds] = useState([]);
  const [loading, setLoading] = useState(false);
  const Request = useRequest();
  const { slug } = useParams();
  useEffect(controlHeader, []);
  useEffect(() => {
    const getData = () => {
      setLoading(true);
      Request.send({ url: `music-videos/${slug}`, method: "GET" }).then(
        (response) => {
          setLoading(false);
          if (response.data) {
            setVideo(response.data.data);
            setRelateds(response.data.releated);
            setOthers(response.data.other);
          }
        }
      );
    };
    getData();
  }, [slug]);

  return { loading, video, others, relateds };
};
