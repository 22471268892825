import { Link } from 'react-router-dom';
import { images } from '../../../assets/utils'
import './style.scss';


export function VerticalVideo({ colsize, cover, artist_name, toArtist, name_video, playlist, className, to, release_year }) {

    return (
        <div className={`item_video ${className} col-6 col-md-${colsize} col-sm-4`}>
            <div className='content'>
                <div className='lable_header'>
                    {playlist ?
                        <div className='lable_right'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 18" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 1.28212V2.56425H7.71788H15.4358V1.28212V0H7.71788H0V1.28212ZM0 6.41061V7.69274H7.71788H15.4358V6.41061V5.12849H7.71788H0V6.41061ZM18.0343 5.16117C18.0155 5.18008 18 6.34565 18 7.75141V10.3073H15.4358H12.8715V11.5894V12.8715H15.4358H18V15.4358V18H19.3073H20.6145V15.4358V12.8715H23.1788H25.743V11.5894V10.3073H23.1792H20.6154L20.6024 7.73045L20.5894 5.15363L19.329 5.14021C18.6359 5.13282 18.0532 5.14227 18.0343 5.16117ZM0 11.5894V12.8715H5.15363H10.3073V11.5894V10.3073H5.15363H0V11.5894Z" fill="white" />
                            </svg>
                        </div>
                        :
                        <div />
                    }
                </div>
                <Link to={to} className='image_video'>
                    <img
                        onError={({ target }) => { target.src = images.default }}
                        src={cover || images.default}
                        width={150}
                        height={150}
                        alt='pictuer'
                    />
                    <div className='icon_video'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                                <path d="M21.2001 33.1013L32.0968 24.9346C32.7267 24.468 32.7267 23.5346 
                                32.0968 23.068L21.2001 14.9013C20.4301 14.318 19.3334 14.878 19.3334 15.8346V32.168C19.3334 
                                33.1246 20.4301 33.6846 21.2001 33.1013ZM24.0001 0.667969C11.1201 0.667969 0.666748 11.1213 
                                0.666748 24.0013C0.666748 36.8813 11.1201 47.3346 24.0001 47.3346C36.8801 47.3346 47.3334 
                                36.8813 47.3334 24.0013C47.3334 11.1213 36.8801 0.667969 24.0001 0.667969ZM24.0001
                                42.668C13.7101 42.668 5.33342 34.2913 5.33342 24.0013C5.33342 13.7113 13.7101 5.33464 
                                24.0001 5.33464C34.2901 5.33464 42.6668 13.7113 42.6668 24.0013C42.6668 34.2913 34.2901
                                42.668 24.0001 42.668Z" fill="#fff">
                                </path>
                            </svg>
                        </div>
                    </div>
                </Link>
                <div className='lable_footer'>
                    <p className="m-0">{release_year}</p>
                </div>
            </div>
            <div className='item_description'>
                <div className='name_video'>
                    <Link to={to}>
                        <span>
                            {name_video}
                        </span>
                    </Link>
                </div>
                <div className='artist_video'>
                    <Link to={toArtist}>
                        <span>
                            {artist_name}
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

