import { useContext, createContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import packageJSON from '../../package.json'


const checkVersion = () => {
    const version = localStorage.getItem('version');
    if (version !== packageJSON.version) {
        if (typeof caches !== 'undefined') {
            caches.keys().then(names => {
                names.forEach(name => {
                    caches.delete(name)
                })
            })
        }
        localStorage.setItem('version', packageJSON.version)
        window.location.reload();
    }
}

const Context = createContext();
export const ThemeContext = ({ children }) => {

    const themeData = localStorage.getItem('theme');
    const openData = localStorage.getItem('opened');

    const [theme, setTheme] = useState(themeData ?? 'light')
    const [opened, setOpened] = useState(openData ? true : false)

    const toggleTheme = () => {
        let newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme)
    }

    useEffect(() => {
        window.document.querySelector('meta[name="color-scheme"]').content = theme;
    }, [theme])

    useEffect(() => {
        localStorage.setItem('opened', opened)
    }, [opened])

    useEffect(() => {
        checkVersion();
    }, [])


    return (
        <Context.Provider value={{
            theme,
            toggleTheme,
            setOpened,
            opened
        }}>
            <Helmet>
                {theme === 'dark' ?
                    <link rel="stylesheet" href="/assets/styles/darkColors.css" />
                    :
                    <link rel="stylesheet" href="/assets/styles/lightColors.css" />
                }
            </Helmet>
            {
                children
            }
        </Context.Provider>
    )
}

export const useTheme = () => useContext(Context);
