import React, { Fragment } from 'react'
import { InfinityList } from '../../components/commons'
import { VerticalMusic } from '../../components/views'
import './style.scss'

export const Musics = () => {

    return (
        <Fragment>
            <div className="allMusic">
                <InfinityList
                    render={(item, i, list) => {
                        return (
                            <div className="sizeBox my-2" key={i}>
                                <VerticalMusic
                                    cover={item?.cover}
                                    track_name={item?.name}
                                    release_year={item?.info?.release_year}
                                    artist_name={item?.artistinfo?.userinfo?.name}
                                    artist_slug={item?.artistinfo?.slug}
                                    musicObject={item}
                                    playlist={list}
                                />
                            </div>
                        )
                    }}
                    url="musics/all-musics?"
                />
            </div>
        </Fragment>
    )

}

