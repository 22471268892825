import React from 'react'
import { Helmet } from 'react-helmet';
import { InfinityList, VerticalMusic } from '../../../components';
import { Link } from 'react-router-dom';

export const Albums = () => {
    return (
        <div className="albumsMusic">
            <Helmet>
                <title>آلبوم ها</title>
            </Helmet>
            <InfinityList
                render={(item, i) => {
                    return (
                        <div className="sizeBox mb-2" key={i}>
                            <Link to={`/music/albums/${item?.slug}`}>
                                <VerticalMusic
                                    cover={item?.cover}
                                    track_name={item?.name}
                                    release_year={item?.info?.release_year}
                                    artist_name={item?.artistinfo?.userinfo?.name}
                                    artist_slug={item?.artistinfo?.slug}
                                    to={`/music/albums/${item?.slug}`}
                                />
                            </Link>
                        </div>
                    )
                }}
                url="musics/all-albums?"
            />
        </div>
    )
}

export { Album } from './album'
