import React from 'react'
import { Link } from 'react-router-dom';

export const controlHeader = () => {
  const element = window.document.getElementById('headercontent');
  const main = window.document.getElementsByClassName('main-content');

  if (element) {
    element.style.display = 'none';
  }
  if (main) {
    if (main.length) {
      main[0].style.paddingTop = '0px';
    }
  }

  return () => {
    if (element) {
      element.style.display = 'block';
    }
    if (main) {
      if (main.length) {
        main[0].style.paddingTop = '50px';
      }
    }
  }
};
export const Header = ({ onOpen }) => {
  return (
    <div id='headercontent' className='headercontent'>
      <div className="d-flex justify-content-between align-items-center">
        <i className='icon ti-menu' onClick={onOpen}></i>
        <span id='title-page' className='title'>خراسان مدیا</span>
        <Link to="/search">
          <i className='icon ti-search'></i>
        </Link>
      </div>
    </div>
  )
}



