import { useCallback, useEffect, useState } from "react";
import { useApp } from "../contexts";
import { useRequest } from "./api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

export const useAccount = () => {
  const { user } = useApp();
  const [userData, setUser] = useState();

  useEffect(() => {
    setUser(user);
  }, [user]);

  return { userData };
};

export const useMyMusic = () => {

  return {};
};

export const useBookmark = () => {
  const [loading, setLoading] = useState(false)
  const [listBookmark, setListBookmark] = useState(null);
  const Request = useRequest();

  const getData = useCallback(() => {
    setLoading(true)
    Request.send({ url: 'user/saveds', method: "GET" }).then((response) => {
      setLoading(false)
      if (response) {
        if (response.status === 200) {
          setListBookmark(response.data);
        }
      }
    });
  }, [setListBookmark]);

  useEffect(() => {
    getData()
    return () => {
      setListBookmark(null);
    }
  }, []);

  return { listBookmark, loading }
};

export const useListLiked = () => {
  const [loading, setLoading] = useState(false)
  const [listLiked, setListLiked] = useState(null);
  const Request = useRequest();

  const getData = useCallback(() => {
    setLoading(true)
    Request.send({ url: 'user/likeds', method: "GET" }).then((response) => {
      setLoading(false)
      if (response) {
        if (response.status === 200) {
          setListLiked(response.data);
        }
      }
    });
  }, [setListLiked]);

  useEffect(() => {
    getData()
    return () => {
      setListLiked(null);
    }
  }, []);

  return { listLiked, loading }
};

export const useEditProfile = () => {
  const { user, login } = useApp();
  const [userData, setUser] = useState();
  const { send } = useRequest();

  useEffect(() => {
    setUser(user);
  }, [user]);

  const edit = async (data) => {
    await send({
      data: data,
      method: 'POST',
      url: 'user/edit'
    }).then((res) => {
      if (res?.status === 200) {
        login(res.data)
        toast.success("اطلاعات با موفقیت ویرایش شد", { theme: "colored" });
      } else {
        toast.error("خطا در عملیات ", { theme: "colored" });
      }
    });
  };

  return { userData, user, edit, setUser };
};

export const useUplodes = () => {

  const Request = useRequest();
  const [data, setData] = useState({})
  const [loading, setloading] = useState({})

  const getData = useCallback(() => {
    setloading(true)
    Request.send({ url: "artist/music", method: "GET" }).then((response) => {
      setloading(false)
      if (response) {
        setData(response.data.data)
      }
    })
  }, [setData])

  useEffect(() => {
    getData()
  }, [])


  const DeleteMusic = async (id) => {
    await Request.send({ url: "/artist/delete-music/" + id, method: "DELETE" }).then((res) => {
      if (res.status == 201) {
        window.location.reload()
      }
    })
  }

  return { data, loading, DeleteMusic }
}

let Slug = 0;
export const useUploadfile = () => {
  const navigate = useNavigate();
  const Request = useRequest();
  const [preview, setPreview] = useState()
  const [percent, setPercent] = useState(0)
  const [data, setData] = useState({})
  const [loading, setloading] = useState({})

  const getData = useCallback(() => {
    setloading(true)
    Request.send({ url: `artist/music/categories`, method: "GET" }).then((response) => {
      setloading(false)
      if (response) {
        setData(response.data)
      }
    })

  }, [setData])

  const postData = useCallback(async (entery) => {
    await Request.send({
      url: `artist/music`, method: "post", data: entery, onUploadProgress: (e) => {
        if (e.total) {
          const value = Math.round((100 * e.loaded) / e.total);
          setPercent(value)
        }
      }
    }).then((response) => {
      setloading(false)
      if (response) {
        if (response.status == 200) {
          navigate('/library/upload');
          toast.success('درخواست با موفقیت ثبت شد', { theme: "colored" })
        }
      }
    })

  })


  useEffect(() => {
    getData()
    Slug = Math.round(Math.random() * 10e+7);
  }, [])

  return { data, loading, preview, setPreview, postData, Slug, percent }
}

export const useUpgrade = () => {
  const { login } = useApp();
  const [preview, setPreview] = useState()
  const Request = useRequest();
  const [data, setData] = useState({})
  const [uploadloading, setuploadloading] = useState(false);

  const getData = useCallback(() => {
    Request.send({ url: "/upgrade", method: "GET" }).then((response) => {
      if (response) {
        if (response.data) {
          if (response.data.user) {
            login(response.data.user);
          }
        }
        setData(response.data)
      }
    })
  }, [setData])

  useEffect(() => {
    getData()
  }, [])

  const upload = async (form) => {
    setuploadloading(true)

    await Request.send({ url: "/upgrade", method: "post", data: form }).then((response) => {
      setuploadloading(false)
      if (response) {
        setData(response.data);
        toast.success('اطلاعات با موفقیت ثبت شد', { theme: "colored" });
        window.location.reload()
      }
    })

  }

  return { data, preview, setPreview, upload, uploadloading }
}

