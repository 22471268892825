import React, { Fragment } from 'react'
import { Form } from '../../components'

export const Verify = ({ handleSubmit, onLogin, setPage, phone, register, navigate }) => {
    return (
        <Fragment>
            <div className='title mb-3'>
                <p className='titletext'>کد تاییدیه را وارد کنید</p>
            </div>
            <Form
                submit={handleSubmit((data) => onLogin({
                    data: data,
                    url: 'verify',
                    massege: "خوش آمدید",
                    Result: () => navigate('/')
                })
                )}
                typeData="object"
                className='form'
            >
                <div className="mb-5">
                    <div className='input'>
                        <input
                            className='input'
                            {...register('verify', { required: true })}
                            name='verify'
                            onChange={({ target: { value } }) => {
                                if (String(value).length === 5) {
                                    onLogin({
                                        data: { mobile: phone, verify: value },
                                        url: 'verify',
                                        massege: "خوش آمدید",
                                        Result: () => navigate('/')
                                    })
                                }
                            }}
                            type="number"
                            style={{ textAlign: "center" }}
                            placeholder='کد تایید را وارد کنید'
                        />
                        <input
                            type="hidden"
                            name="mobile"
                            value={phone}
                        />
                    </div>
                </div>
                <button type="submit" id='submit-code' className="buttonone w-100">تایید</button>
                <button onClick={() => setPage('login')} className="buttontwo">بازگشت</button>
            </Form>
        </Fragment >
    )
}

