import { Link } from 'react-router-dom';
import './style.scss';

export function Section({ children, title, description, toMore, stateMore }) {
    return (
        <div className='Category'>
            <div className='header'>
                <div>
                    <h2 className='title'>{title}</h2>
                    {description &&
                        <span className='sub'>{description}</span>
                    }
                </div>
                {
                    toMore &&
                    <Link to={toMore} state={stateMore}>
                        <div className='more'>
                            <p>بیشتر</p><i className='icon ti-angle-left m-0'></i>
                        </div>
                    </Link>
                }
            </div>
            <div className='children'>{children}</div>
        </div>
    )
}