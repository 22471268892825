import React from 'react'
import { HorizontalImage } from '../../views';

export const PlayList = ({ list = [], onHide }) => {
    return (
        <div className='playlistModal'>
            <header className='d-flex aling-items-center justify-content-between mb-4'>
                <h5>پیشنهادی خراسان مدیا</h5>
                <i onClick={onHide} className='icon ti-close'></i>
            </header>

            <main>
                {list.map((item, i) => <div className="col-12 my-3"><HorizontalImage
                    musicObject={item}
                    playlist={list}
                    cover={item?.cover}
                    artist_name={item?.artistinfo?.userinfo?.name}
                    artist_slug={item?.artistinfo?.slug}
                    track_name={item?.name}
                    number={i + 1}
                    slug={item?.slug}
                    duration={item?.duration}
                /></div>)}
            </main>
        </div>
    )
}