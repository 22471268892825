import { Fragment } from "react";
import { Form } from "../../components";
import { useUpgrade } from "../../hooks";
import './style.scss'

export const Upgrade = () => {
    const { data, preview, setPreview, upload } = useUpgrade();
    return (
        <Fragment>
            {data?.status
                ?
                data?.status == "wait" ?
                    <div className='alert alert-success' role="alert">
                        <h4 className="alert-heading">درخواست شما با موفقیت ثبت شد</h4>
                        <p>درخواست شما با موفقیت ثبت شده همکاران ما در در حال بررسی درخواست شما هستند</p>
                        <hr />
                        <p className="mb-0">وضعیت درخواست : درحال بررسی</p>
                    </div>
                    :
                    <div className='alert alert-warning' role="alert">
                        <h4 className="alert-heading">درخواست شما رد  شد</h4>
                        <p>{data?.message}</p>
                        <hr />
                        <p className="mb-0">وضعیت درخواست : رد شده</p>
                    </div>
                :
                null
            }
            <Form className='upgrade' submit={upload}>
                <h2>لطفا عکس کارت ملی خود را بارگذاری نموده و درخواست ارتقا را ثبت نموده و همکاران ما پس از بررسی با شما تماس خواهند گرفت</h2>
                <div className='national'>
                    {preview
                        ?
                        <img
                            className='image'
                            src={URL.createObjectURL(preview)}
                            alt="preview"
                            priority
                        />
                        :
                        <p>لطفا عکس کارت ملی خود را بارگذاری کنید</p>
                    }

                    <input
                        onChange={(e) => setPreview(e.target.files[0])}
                        name="attachments"
                        accept="image/*"
                        type="file"
                    />
                </div>
                <div className='submit'>
                    <button type="submit">
                        ثبت
                    </button>
                </div>
            </Form>
        </Fragment>
    )
}
