import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export const controlFooter = () => {
    const element = window.document.getElementById('footercontent');
    const main = window.document.getElementsByClassName('main-content');
    if (element) {
        element.style.display = 'none';
    }
    if (main) {
        if (main.length) {
            main[0].style.paddingTop = '0px';
        }
    }

    return () => {
        if (element) {
            element.style.display = 'block';
        }
        if (main) {
            if (main.length) {
                main[0].style.paddingTop = '50px';
            }
        }
    }
};

export const Footer = () => {
    const location = useLocation()
    return (
        <div className='footercontent' id='footercontent' style={{ zIndex: 11 }}>
            <div className="footer" style={{ zIndex: 11 }}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <Link to="/">
                        <div className={`item ${location.pathname === "/" && " active"}`}>
                            <i className='icon ti-home'></i>
                            <p>خانه</p>
                        </div>
                    </Link>
                    <Link to="/suggest">
                        <div className={`item ${location.pathname === "/suggest" && " active"}`}>
                            <i className='icon ti-star'></i>
                            <p>پیشنهادی</p>
                        </div>
                    </Link>
                    <Link to="/category">
                        <div className={`item ${location.pathname === "/category" && " active"}`}>
                            <i className='icon ti-layout-grid2'></i>
                            <p>دسته بندی</p>
                        </div>
                    </Link>
                    <Link to="/library">
                        <div className={`item ${location.pathname === "/library" && " active"}`}>
                            <i className='icon ti-gallery'></i>
                            <p>کتابخانه</p>
                        </div>
                    </Link>
                    <Link to="/account">
                        <div className={`item ${location.pathname === "/account" && " active"}`}>
                            <i className='icon ti-user'></i>
                            <p>پروفایل</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
