import React, { Fragment } from 'react'
import { Loader, LoaderSlider, Section, SliderBanner, SliderBase, VerticalVideo } from '../../components';
import { useLanding } from '../../hooks';
import './style.scss';
export const Landing = () => {
    const { landing, loading, slug } = useLanding();
    return (
        <Fragment>
            <div className='concert'>
                <section className={landing?.sliders?.length ? 'mt-3' : ""}>
                    {!loading ?
                        landing.sliders?.length ?
                            <SliderBanner
                                pagination={true}
                                data={landing?.sliders}
                                renderItem={(item, i) => {
                                    return (
                                        <a href={item.href}>
                                            <img
                                                key={i}
                                                src={item.image}
                                                alt='pictuer'
                                                className='image'
                                            />
                                        </a>
                                    )
                                }}
                            /> :
                            null
                        :
                        <div className='container'>
                            <Loader className='loader col-12 my-2' count={1} />
                        </div>
                    }
                </section>
                <div className={`listSlider ${!landing?.sliders?.length ? "p-0" : ""}`}>
                    <section>
                        <div className="container">
                            <Section title="تولیدات جدید" toMore={`/videos/${slug}/videos`}>
                                {!loading
                                    ?
                                    landing?.newVideos?.length ? <SliderBase
                                        pagination={false}
                                        breakpoints={{
                                            768: {
                                                perPage: 3,
                                            },
                                            479: {
                                                perPage: 2,
                                            },
                                            1024: {
                                                perPage: 3
                                            }
                                        }}
                                        prePage={4}
                                        data={landing?.newVideos
                                        }
                                        renderItem={(item, i) => {
                                            return (
                                                <VerticalVideo
                                                    key={i}
                                                    cover={item?.cover}
                                                    name_video={item?.name}
                                                    to={"/video/" + item?.slug}
                                                    release_year={item?.info?.release_year}
                                                />
                                            )
                                        }}
                                    /> :
                                        null
                                    :
                                    <LoaderSlider className='loaderTree' />
                                }
                            </Section>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <Section title="برای شما" toMore={`/videos/${slug}/forYou`}>
                                {!loading && landing?.newForyouVideos
                                    ?
                                    landing?.newForyouVideos?.length ?
                                        <SliderBase
                                            pagination={false}
                                            breakpoints={{
                                                768: {
                                                    perPage: 3,
                                                },
                                                479: {
                                                    perPage: 2,
                                                },
                                                1024: {
                                                    perPage: 4
                                                }
                                            }}
                                            prePage={4}
                                            data={landing?.newForyouVideos}
                                            renderItem={(item, i) => {
                                                return (
                                                    <VerticalVideo
                                                        key={i}
                                                        cover={item?.cover}
                                                        name_video={item?.name}
                                                        to={"/video/" + item?.slug}
                                                        release_year={item?.info?.release_year}
                                                    />
                                                )
                                            }}
                                        />
                                        :
                                        null
                                    :
                                    <LoaderSlider className='loaderTree' />
                                }
                            </Section>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <Section title="پیشنهادی خراسان مدیا" toMore={`/videos/${slug}/suggested`}>
                                {!loading
                                    ?
                                    landing?.newSuggestVideos?.length ?
                                        <SliderBase
                                            pagination={false}
                                            breakpoints={{
                                                768: {
                                                    perPage: 3,
                                                },
                                                479: {
                                                    perPage: 2,
                                                },
                                                1024: {
                                                    perPage: 4
                                                }
                                            }}
                                            prePage={4}
                                            data={landing?.newSuggestVideos}
                                            renderItem={(item, i) => {
                                                return (
                                                    <VerticalVideo
                                                        key={i}
                                                        cover={item?.cover}
                                                        name_video={item?.name}
                                                        to={"/video/" + item?.slug}
                                                        release_year={item?.info?.release_year}
                                                    />
                                                )
                                            }}
                                        />
                                        : null
                                    :
                                    <LoaderSlider className='loaderTree' />
                                }
                            </Section>
                        </div>
                    </section>
                </div>

            </div>
        </Fragment>
    )
}
