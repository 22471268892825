import React, { Fragment } from 'react'
import { InfinityList } from '../../components/commons'
import { VerticalMusic } from '../../components/views'
import { Helmet } from 'react-helmet'

export const Superior = () => {
    return (

        <Fragment>
            <Helmet>
                <title>موسیقی های برتر</title>
            </Helmet>
            <div className="allMusic">
                <InfinityList
                    render={(item, i, list) => {
                        return (
                            <div className="col-md-3 col-sm-4 col-6 my-2" key={i}>
                                <VerticalMusic
                                    resize='image_resize'
                                    cover={item?.cover}
                                    track_name={item?.name}
                                    release_year={item?.info?.release_year}
                                    artist_name={item?.artistinfo?.userinfo?.name}
                                    artist_slug={item?.artistinfo?.slug}
                                    musicObject={item}
                                    playlist={list}
                                />
                            </div>
                        )
                    }

                    }
                    url="musics/best-musics?"
                />
            </div>

        </Fragment>
    )
}

