import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";
import { SliderBase } from "../../views/sliders";

export const Loader = (props) => {
  const loop = () => {
    var jsx = [];
    for (let index = 0; index < props.count; index++) {
      jsx.push(
        <div key={index} className={props.className} style={props.style}>
          <ContentLoader
            speed={1.5}
            backgroundColor='var(--loaderbackground)'
            foregroundColor='var(--loader)'
            style={{ width: "100%", height: "100%", borderRadius: props.borderRadius || "10px" }}
          >
            <rect
              x="0"
              y="0"
              rx="10"
              ry="10"
              width="100%"
              height="100vh"
            />
          </ContentLoader>
        </div>
      );
    }
    return jsx;
  };
  return loop()
}

export const LoaderSlider = ({ className }) => {
  return (
    <SliderBase
      pagination={false}
      prePage={4}
      data={[1, 2, 3, 4]}
      breakpoints={{
        768: {
          perPage: 3,
        },
        479: {
          perPage: 2,
        },
        1024: {
          perPage: 4
        }
      }}
      type='slide'
      renderItem={(item, i) => {
        return (
          <Loader key={i} className={`m-2 ${className || 'loaderTow'}`} count={1} />
        )
      }}
    />
  )
}

export const Loaderlinear = () => {

  return (
    <Fragment>
      <div className="row px-3">
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item, i) => {
          return (
            <div className="col-md-6 col-12 my-2" key={i}>
              <Loader className='loaderFure' count={1} />
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}

export const LoaderSingleLine = ({ count = [] }) => {
  return (
    <div className="row">
      {count.map((item, i) => {
        return (
          <Loader key={i} className='col-12 my-2 loaderFure' count={1} />
        )
      })}
    </div>
  )
}

