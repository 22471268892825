import { Link } from 'react-router-dom';
import './style.scss';

export function CategoryImage({ to, textStyle, image, label, icon, className, style }) {
  return (
    <div className='CategoryImage'>
      <Link to={to}>
        <img 
          style={style}
          className={className || 'img'}
          src={image}
          alt='pic'
        />
        <div className='lable'>
          {icon}
          <p style={textStyle}>{label}</p>
        </div>
      </Link>
    </div>
  )
}
