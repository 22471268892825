import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const SliderBanner = ({data , renderItem}) => {
  let settings = {
    centerMode: true,
    infinity: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <div className='premiere-carousel'>
      <div className="container">
        <Slider {...settings}>
          {data?.map((item, i) => (
            <div className='item' key={i}>
              {renderItem(item)}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
