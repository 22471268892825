import React, { Fragment, useEffect } from 'react'
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { InfinityList } from '../../components/commons';
import { VerticalVideo } from '../../components/views';
import './style.scss';
export const MusicVideos = () => {
    const { slug } = useParams()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [slug])

    return (
        <Fragment>
            <Helmet>
                <title>موزیک ویدئو ها</title>
            </Helmet>
            <div className='videopage'>
                <div className="container">
                    <div className="headerpage d-flex justify-content-between align-items-center">
                        <div className="title">
                            <h3>موزیک ویدئو ها</h3>
                        </div>
                    </div>
                    <div className="content p-0">
                        <InfinityList
                            render={(video, i) => {
                                return (
                                    <div className='sizeBox mb-2' key={i}>
                                        <VerticalVideo
                                            cover={video?.cover}
                                            to={'/music-videos/' + video?.slug}
                                            artist_name={video?.artistinfo?.userinfo?.name}
                                            toArtist={'/artist/' + video?.artistinfo?.slug}
                                            release_year={video?.info?.release_year}
                                            name_video={video?.name}
                                        />
                                    </div>
                                )
                            }
                            }
                            url="music-videos?"
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export { MusicVideo } from './music-video';