import React, { Fragment } from 'react'
import { useArtist } from '../../hooks'
import { LoaderSlider, Section, SliderBase, SpinnerLoading, VerticalMusic, VerticalVideo } from '../../components';
import { images } from '../../assets';
function sortItemsinCategories(list = []) {

    let categories = {};
    list.forEach(product => {
        if (!Object.keys(categories).includes(product.category_slug.slug)) {
            categories[product.category_slug.slug] = {
                id: product.category_slug.slug,
                name: product.category_slug.name,
                type: product.category_slug.slug,
                content: []
            };
        }
    });

    list.forEach(product => {
        Object.keys(categories).forEach(category => {
            if (product.category_slug.slug === category) {
                categories[category].content.push(product);
            }
        });

    });

    return categories;

}

export const ArtistPage = () => {
    const { loading, artist, navigate } = useArtist();

    return (
        <div className="Artist">
            {loading ?
                (<SpinnerLoading />)
                :

                artist ? (
                    <Fragment>
                        <div className='imagecont' style={{ background: `url(${artist?.musics?.[0] ? artist?.musics?.[0]?.cover : artist?.userinfo?.profile})` }}>
                            <div className='goback' onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
                                <i className='far fa-arrow-left' />
                            </div>
                            <p className='fullname'>{artist?.musics?.[0] ? artist?.musics?.[0]?.name : artist?.userinfo?.name}</p>
                            <div className="gradients"></div>
                        </div>
                        <div className='cont px-4'>
                            <div className='mt-lg-5 pt-lg-4 mt-3 pt-3 mb-3 pb-2'>
                                <h2 className='title'>آخرین کار</h2>
                                <div className="col-12 mt-4">
                                    <div className='lastmusic'>
                                        {artist?.musics?.[0]
                                            ?
                                            <div className="d-flex align-items-center">
                                                <div className='image_music' onClick={() => { }}>
                                                    <img
                                                        onError={({ target }) => { target.src = images.default }}
                                                        className='img'
                                                        src={artist?.musics?.[0]?.cover || images.default}
                                                        alt='pictuer'
                                                    />
                                                    <div className='info'>
                                                        <p className='songname'>{artist?.musics?.[0].name}</p>
                                                        <span>{artist?.musics?.[0].info?.release_year}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <p>نتیجه ای یافت نشد!!</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            {artist?.musics?.length > 0
                                ?
                                <div className="my-2 py-2">
                                    <Section title="همه موزیک ها" toMore={`/artist/musics/${artist.slug}`}>
                                        {!loading ?
                                            <SliderBase
                                                pagination={false}
                                                prePage={4}
                                                data={artist?.musics}
                                                breakpoints={{
                                                    768: {
                                                        perPage: 3,
                                                    },
                                                    479: {
                                                        perPage: 3,
                                                    },
                                                    1024: {
                                                        perPage: 4
                                                    }
                                                }}
                                                renderItem={(item, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <VerticalMusic
                                                                cover={item?.cover}
                                                                track_name={item?.name}
                                                                release_year={item?.info?.release_year}
                                                                artist_name={item?.artistinfo?.userinfo?.name}
                                                                artist_slug={item?.artistinfo?.slug}
                                                                musicObject={item}
                                                                playlist={artist?.musics}
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            />
                                            :
                                            <LoaderSlider />
                                        }
                                    </Section>
                                </div>
                                :
                                null
                            }
                            {artist?.albums?.length > 0
                                ?
                                <div className="my-2 py-2">
                                    <Section title="همه آلبوم ها" toMore={`/artist/albums/${artist.slug}`}>
                                        {!loading ?
                                            <SliderBase
                                                pagination={false}
                                                prePage={4}
                                                data={artist?.albums}
                                                breakpoints={{
                                                    768: {
                                                        perPage: 3,
                                                    },
                                                    479: {
                                                        perPage: 3,
                                                    },
                                                    1024: {
                                                        perPage: 4
                                                    }
                                                }}
                                                renderItem={(item, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <VerticalMusic
                                                                cover={item?.cover}
                                                                track_name={item?.name}
                                                                release_year={item?.info?.release_year}
                                                                artist_name={item?.artistinfo?.userinfo?.name}
                                                                artist_slug={item?.artistinfo?.slug}
                                                                to={'/music/albums/' + item?.slug}
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            />
                                            :
                                            <LoaderSlider />
                                        }
                                    </Section>
                                </div>
                                :
                                null
                            }
                            {artist?.music_videos?.length > 0
                                ?
                                <div className="my-2 py-2">
                                    <Section title="همه موزیک ویدئو ها" toMore={`/artist/music-videos/${artist.slug}`}>
                                        {!loading ?
                                            <SliderBase
                                                pagination={false}
                                                prePage={4}
                                                data={artist?.music_videos}
                                                breakpoints={{
                                                    768: {
                                                        perPage: 3,
                                                    },
                                                    479: {
                                                        perPage: 2,
                                                    },
                                                    1024: {
                                                        perPage: 4
                                                    }
                                                }}
                                                renderItem={(item, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <VerticalVideo
                                                                to={`/music-videos/${item?.slug}`}
                                                                cover={item.cover}
                                                                name_video={item.name}
                                                                toArtist={'/artist/' + item?.artistinfo?.slug}
                                                                release_year={item?.info?.release_year}
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            />
                                            :
                                            <LoaderSlider />
                                        }
                                    </Section>
                                </div>
                                :
                                null
                            }

                            {artist?.videos?.length > 0
                                ?
                                Object.entries(sortItemsinCategories(artist?.videos)).map((item) => item[1]).map((category) => {

                                    return (
                                        <div className="my-2 py-2">
                                            <Section title={category.name} toMore={`/artist/videos/${artist.slug}/${category.type}`}>
                                                {!loading ?
                                                    <SliderBase
                                                        pagination={false}
                                                        prePage={4}
                                                        data={artist?.videos}
                                                        breakpoints={{
                                                            768: {
                                                                perPage: 3,
                                                            },
                                                            479: {
                                                                perPage: 2,
                                                            },
                                                            1024: {
                                                                perPage: 4
                                                            }
                                                        }}
                                                        renderItem={(item, i) => {
                                                            return (
                                                                <div key={i}>
                                                                    <VerticalVideo
                                                                        to={`/video/${item?.slug}`}
                                                                        cover={item.cover}
                                                                        toArtist={'/artist/' + item?.artistinfo?.slug}
                                                                        name_video={item.name}
                                                                        release_year={item?.info?.release_year}
                                                                    />
                                                                </div>
                                                            )
                                                        }}
                                                    />
                                                    :
                                                    <LoaderSlider />
                                                }
                                            </Section>
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                        </div>

                    </Fragment>
                ) :
                    (
                        <div style={{ marginTop: "105px", padding: "200px 0", display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                            <h5 style={{ color: 'var(--primary)', textAlign: "center" }}>نتیجه ای یافت نشد!!</h5>
                        </div>
                    )
            }
        </div>
    )
}

