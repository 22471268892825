import React, { createContext, useContext, useRef, useState } from 'react';
import { VideoPlayer } from '../components'
const CONTEXT = createContext();



export function VideoContext({ children }) {
    const [service, setService] = useState(false);
    const [accept, setAccept] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [typeModal, setTypeModal] = useState("accept");
    const [togglePlay, settogglePlay] = useState(false)
    const [end, setEnd] = useState(false)
    const [resolution, setResolution] = useState('normal')
    const [Speed, setSpeed] = useState(1)
    const [controller, setController] = useState(true)
    const [volume, setVolume] = useState(false)
    const [valueVolume, setValueVolume] = useState(0.5)
    const [screen, setScreen] = useState(false)
    const [source, setSource] = useState(null)
    const playerVideo = useRef()
    const range = useRef()

    const Play = () => {
        if (playerVideo.current) {
            playerVideo.current?.play()
        }
    }
    const Pause = () => {

        if (playerVideo.current) {
            playerVideo.current?.pause()
        }
    }
    const ToggleMuted = () => {
        setVolume(!volume)
        if (!volume) {
            setValueVolume(0)
        } else {
            setValueVolume(playerVideo.current.volume)
        }
    }
    const ChangeVolume = (value) => {
        if (playerVideo.current) {
            playerVideo.current.volume = value
            setValueVolume(value)
        }
    }
    const FullScreen = async () => {
        if (playerVideo.current) {
            await playerVideo.current.toggleFullscreen();
            setScreen(!screen)
        }
    }

    const ChangeCurrentTime = (seconds) => {
        return () => {
            const { playerVideo: P } = playerVideo.current.getState()
            playerVideo.current.seek(P.currentTime + seconds)
        }
    }
    const ChangePlaybackRateRate = (steps) => {
        return () => {
            playerVideo.current.playbackRate = steps
            setSpeed(steps)
        }
    }
    const ChangePlaybackRateRate2 = (steps) => {
        if (playerVideo.current) {
            playerVideo.current.playbackRate = steps
            setSpeed(steps)
        }
    }
    const ChangeQuality = (q) => {
        const { playerVideo: P } = playerVideo.current.getState()
        setResolution(q)
        playerVideo.current.load()
        playerVideo.current.seek(P.currentTime)
        settogglePlay(false)
    }
    const toggleService = (active) => {
        if (active) {
            Pause();
        }
        if (!active) {
            setController(true);
            setTypeModal('accept');
            setShowModal(false);
            setService(false);
        } else {
            setController(false);
            setTypeModal('accept');
            setShowModal(true);
        }
    }
    const toggleAccept = (value) => {
        if (value) {
            setAccept(true);
            setShowModal(false);
            setTypeModal('accept');
            setService(true);
        } else {
            setAccept(false);
            setShowModal(false);
            setTypeModal('accept');
            setService(false);
            window.document.getElementById("custom-switch2").checked = false;
            window.document.getElementById("custom-switch").checked = false;
        }
    }

    const toggleModal = ({ type, data }) => {
        switch (type) {
            case "accept":
                setShowModal(true);
                setTypeModal('accept');
                break;
            case "product":
                setShowModal(data);
                setTypeModal('product');
                break;
            case "closeAccept":
                toggleAccept(false);
                break;
            case "closeProduct":
                setShowModal(false);
                setTypeModal('product');
                break;
        }
    }
    return (
        <CONTEXT.Provider value={{
            service,
            setService,
            accept,
            setAccept,
            setShowModal,
            showModal,
            typeModal,
            toggleModal,
            toggleService,
            toggleAccept,
            togglePlay,
            settogglePlay,
            end,
            setEnd,
            resolution,
            setResolution,
            Speed,
            setSpeed,
            controller,
            setController,
            playerVideo,
            Play,
            Pause,
            ToggleMuted,
            FullScreen,
            ChangeCurrentTime,
            ChangePlaybackRateRate,
            ChangePlaybackRateRate2,
            ChangeQuality,
            volume,
            valueVolume,
            range,
            screen,
            setScreen,
            source,
            setSource,
            ChangeVolume
        }}>
            {children}
            <VideoPlayer />
        </CONTEXT.Provider>
    )
}

export const useVideoContext = () => useContext(CONTEXT);
