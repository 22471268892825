import { Link } from "react-router-dom";
import { Artist, VerticalMusic, VerticalVideo } from "../../components";

export const Result = ({ object }) => {

    let JSX = [];
    Object.entries(object).map(([key, value]) => {
        switch (key) {
            case 'musics':
                value?.map?.((item, i) => {
                    if (item.type === 'music') {
                        JSX.push(
                            <div className='sizeBox' key={i}>
                                <VerticalMusic
                                    cover={item?.cover}
                                    track_name={item?.name}
                                    release_year={item?.info?.release_year}
                                    artist_name={item?.artistinfo?.userinfo?.name}
                                    artist_slug={item?.artistinfo?.slug}
                                    musicObject={item}
                                    playlist={[item]}
                                />
                            </div>
                        )
                    } else {
                        JSX.push(
                            <div className='sizeBox mt-3' key={i}>
                                <VerticalVideo
                                    cover={item?.cover}
                                    to={'/music-videos/' + item?.slug}
                                    artist_name={item?.artistinfo?.userinfo?.name}
                                    release_year={item?.info?.release_year}
                                    toArtist={'/artist/' + item?.artistinfo?.slug}
                                    name_video={item?.name}
                                />
                            </div>
                        )
                    }
                    return null;
                })

                break;
            case 'albums':
                value?.map?.((item, i) => {
                    JSX.push(
                        <Link key={i} to={`/music/albums/${item?.slug}`}>
                            <VerticalMusic
                                cover={item?.cover}
                                track_name={item?.name}
                                release_year={item?.info?.release_year}
                                artist_name={item?.artistinfo?.userinfo?.name}
                                artist_slug={item?.artistinfo?.slug}
                                to={`/music/albums/${item?.slug}`}
                            />
                        </Link>
                    )
                    return null;
                })

                break;
            case 'artists':
                value?.map?.((item, i) => {
                    JSX.push(
                        <div className='sizeBox' key={i}>
                            <Artist
                                to={`/artist/${item?.artistinfo?.slug}`}
                                name={item?.name}
                                image={item?.profile}
                            />
                        </div>
                    )
                    return null;
                })
                break;
            case 'videos':
                value?.map?.((item, i) => {
                    JSX.push(
                        <div className='sizeBox mt-3' key={i}>
                            <VerticalVideo
                                to={`/video/${item?.slug}`}
                                cover={item.cover}
                                name_video={item.name}
                                release_year={item?.info?.release_year}
                            />
                        </div>
                    )
                    return null;
                })
                break;
            default:
                break;
        }
        return null;
    });

    return JSX;

}