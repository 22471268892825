import { useCallback, useEffect, useState } from "react";
import { useRequest } from "./api";

export const useOperator = ({ urlLike, urlBookmark, properties, slug, data, setData }) => {

    const { send } = useRequest();

    const [activities, setActivities] = useState({
        countLike: 0,
        statusLike: 0,
        countDislike: 0,
        statusDislike: 0
    })

    const [loading, setLoading] = useState({
        bookmark: false,
        like: false,
        dislike: false
    })

    useEffect(() => {
        if (properties) {
            setActivities({
                countLike: properties?.likes,
                countDislike: properties?.dislikes,
                statusLike: properties?.isLike,
                statusDislike: properties?.isDislike
            })
        }
    }, [properties])

    const onLike = useCallback((type) => {
        setLoading({ ...loading, [type]: true });
        send({ url: `${urlLike}?type=${type}`, data: { id: slug }, method: "GET" }).then((response) => {
            setLoading({ ...loading, [type]: false });
            if (response?.status === 200) {
                setActivities({
                    countLike: response?.data?.likes,
                    countDislike: response?.data?.dislikes,
                    statusLike: response?.data?.isLike,
                    statusDislike: response?.data?.dislikes
                })
            }
        })

    }, [urlLike, slug, setActivities, loading, send])

    const onBookmark = useCallback((entry, index = -1) => {
        setLoading({ ...loading, bookmark: true });
        send({ url: urlBookmark + entry, data: { id: entry }, method: "GET" }).then((response) => {
            setLoading({ ...loading, bookmark: false });
            if (response?.status === 200) {
                if (index >= 0) {
                    let newData = [...data?.data]
                    newData[index].properties.isBookmark = response?.data
                    setData({ ...data, data: newData });
                } else {
                    setData({ ...data }, data.properties.isBookmark = response?.data)
                }
            }
        })

    }, [data, send, urlBookmark, loading, setData])



    return { onLike, activities, onBookmark, loading }
}