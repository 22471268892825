import React, { useEffect } from 'react'
import { InfinityList, VerticalMusic } from '../../components'
import { Link, useParams } from 'react-router-dom'

const ArtistAlbums = () => {
    const { slug } = useParams()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [slug])
    return (
        <div className='videos'>
            <div className="container">

                <div className="content p-0">
                    <InfinityList
                        render={(item, i) => {
                            return (
                                <div className="sizeBox mb-2" key={i}>
                                    <Link to={`/music/albums/${item?.slug}`}>
                                        <VerticalMusic
                                            cover={item?.cover}
                                            track_name={item?.name}
                                            release_year={item?.info?.release_year}
                                            artist_name={item?.artistinfo?.userinfo?.name}
                                            artist_slug={item?.artistinfo?.slug}
                                            to={`/music/albums/${item?.slug}`}
                                        />
                                    </Link>
                                </div>
                            )
                        }}
                        url={`artists/albums/${slug}?`}
                    />
                </div>
            </div>
        </div>
    )
}

export default ArtistAlbums