import axios from "axios";
import { BaseUrlAPI } from "../assets";
import { useApp } from "../contexts";
import { toast } from "react-toastify";

export const useRequest = () => {
  const { user, logout } = useApp();

  const axiosInstance = axios.create({
    baseURL: BaseUrlAPI,
  });
  const send = async ({ data, url, method, onUploadProgress = null }) => {
    let header = {
      // 'Content-Type': 'application/json',
      Authorization: "Bearer " + user?.token,
    };

    if (!user) {
      header = {};
    }
    const options = {
      method: method || "post",
      url,
      headers: header,
      onUploadProgress,
      data: data,
    };
    return await axiosInstance(options)
      .then((response) => {
        if (response.status === 401) {
          logout();
          window.location.reload();
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.status === 401) {
            logout();
            return false;
          } else {
            if (typeof error.response?.data?.message == "object") {
              Object.entries(error.response?.data?.message).map((errors) => {
                if (Array.isArray(errors[1])) {
                  errors[1].map((item) => {
                    toast.error(item);
                  });
                } else {
                  let message = errors[1];
                  toast.error(message);
                }
              });
            } else {
              toast.error(
                error.response?.data?.message || "مشکلی پیش آمده است"
              );
            }
            return false;
          }
        }
      });
  };

  return { send };
};
