import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRequest } from "./api";
import { controlHeader } from "../components";

export const useLanding = () => {
  const [landing, setLanding] = useState();
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const request = useRequest();
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await request
        .send({ url: `landing/${slug}`, method: "GET" })
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            setLanding(response.data);
          }
        });
    };
    getData();
  }, [slug]);
  return { landing, loading, slug };
};

export const useVideo = () => {

  const [video, setVideo] = useState();
  const [others, setOthers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const request = useRequest();
  useEffect(controlHeader, []);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await request
        .send({ url: `videos/video/${slug}`, method: "GET" })
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            setVideo(response.data.data);
            setOthers(response.data.other);
          }
        });
    };
    getData();
  }, [slug]);

  return { video, others, loading, slug ,setVideo};

}

