import React from 'react'
import { useAlbum, useOperator } from '../../../hooks'
import { HorizontalImage, Loaderlinear, SpinnerLoading } from '../../../components';
import { useMusicContext } from '../../../contexts';

export const Album = () => {
  const { album, loading, navigate, handleSharing, setAlbum } = useAlbum();
  const { playMusichandler, source, onTogglePlay, statusPlay } = useMusicContext();
  const operator = useOperator({
    data: album,
    setData: (newData) => {
      setAlbum({ ...album, ...newData.data })
    },
    slug: album?.slug,
    properties: album?.properties,
    urlBookmark: `albums/bookmark/`
  });
  return (

    <div className='albumsMusic' style={{ paddingTop: '0' }}>
      {
        loading ?
          <SpinnerLoading />
          :
          <>
            <div className='contentSingle'>
              <div className='imagecont' style={{ background: `url(${album?.cover})` }}>
                <div className='goback' onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
                  <i className='far fa-arrow-left' />
                </div>
                <p className='namealbum'>{album?.name}</p>
                <p className='namesinger'>{album?.artistinfo?.userinfo?.name}</p>
                <div className="gradients"></div>
                <div className='btngroup'>
                  <button className='btnshare'>
                    <i className='far fa-share-alt' onClick={handleSharing} />
                  </button>
                  <button className='btnplay' onClick={() => {
                    if (album?.musics?.find?.((itm) => itm.slug === source?.slug)) {
                      onTogglePlay()
                    } else {
                      playMusichandler(album?.musics, 0)
                    }
                  }}>
                    {
                      album?.musics?.find?.((itm) => itm.slug === source?.slug)
                        &&
                        statusPlay ?
                        <>
                          <i className='far fa-pause' />
                          <span>توقف</span>
                        </> :
                        <>
                          <i className='far fa-play' />
                          <span>پخش</span>
                        </>
                    }
                  </button>
                  <button className='btnlike'>
                    <i className={`${album?.properties?.isBookmark ? "fas" : "far"} ${operator.loading.bookmark ? "spinner-border" : "fa-heart"}`}
                      onClick={() => { operator.onBookmark(album?.slug) }}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="mt-5 pt-2 px-2">
                {album
                  ?
                  album?.musics?.map((item, i) => {
                    return (
                      <div className="col-12 my-3" key={i}>
                        <HorizontalImage
                          musicObject={item}
                          playlist={album?.musics}
                          cover={item?.cover}
                          artist_name={item?.artistinfo?.userinfo?.name}
                          artist_slug={item?.artistinfo?.slug}
                          track_name={item?.name}
                          number={i + 1}
                          slug={item.slug}
                          duration={item?.duration} />
                      </div>
                    )
                  })
                  :
                  <Loaderlinear />
                }
              </div>

            </div>
          </>
      }
    </div>


  )

}

