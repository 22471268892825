import { Splide, SplideSlide } from '@splidejs/react-splide';

export const SliderBase = ({ data, renderItem, prePage = 1, breakpoints, pagination, className,type }) => {

    return (
        <Splide
            className={"padding-slider " + className}
            options={{
                direction: "rtl",
                type: type||'slide',
                autoplay: false,
                perPage: prePage,
                perMove: 1,
                breakpoints,
                pagination,
                interval: 5000
            }}>
            {data?.map((item, i) => (
                <SplideSlide key={i}>
                    {renderItem(item, i)}
                </SplideSlide>
            ))}
        </Splide>
    )
}
