import React, { Fragment } from 'react'
import "./style.scss";
import { useNavigate } from 'react-router';
import { useApp, useMusicContext, useVideoContext } from '../../../contexts';
import { Accordion } from 'react-bootstrap';
import { useOperator } from '../../../hooks';
export const Poster = ({ video, type = 'video', setVideo }) => {
    const navigate = useNavigate();
    const { user } = useApp();
    const { setSource, FullScreen } = useVideoContext();
    const { source: music, playMusichandler, onTogglePlay } = useMusicContext();
    const operator = useOperator({
        data: video,
        setData: setVideo,
        properties: video?.properties,
        slug: video?.slug,
        urlLike: `${type === 'music-video' ? 'musics' : 'videos'}/action/${video?.slug}`,
        urlBookmark: `${type === 'music-video' ? 'musics' : 'videos'}/bookmark/`
    });
    return (
        <Fragment>
            <div className='boxPoster'>
                <div className='imagecont'>
                    <p className='namealbum'>{video?.name}</p>
                    <p className='namesinger'>{video?.artistinfo?.userinfo?.name}</p>
                    <img src={video?.cover} alt={video?.name} style={{ width: "100%", height: "100%" }} />
                    <div className="gradients">
                    </div>
                </div>

                <div className="btnMovie">
                    <div className='goback' onClick={() => navigate(-1)}>
                        <i className='far fa-arrow-left' />
                    </div>
                    <div className='btngroup mb-3 px-4'>
                        <button
                            onClick={() => {
                                if (user) {
                                    setSource(video);
                                    FullScreen();
                                } else {
                                    navigate('/account')
                                }
                            }}>پخش</button>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className='footerPlayer'>
                    <div className="d-flex align-items-center w-100">
                        <Accordion defaultActiveKey="0" className='w-100'>
                            <Accordion.Item className='d-flex row border-0'>
                                <div className='icons mt-3'>
                                    <div className='icon'>
                                        <i className='far fa-share-alt'
                                            onClick={
                                                async () => {
                                                    try {
                                                        if (navigator.canShare) {
                                                            await navigator.share({
                                                                title: video?.name,
                                                                text: `${type === 'video' ? ' ویدئو ' : ' موزیک ویدئو '} ` + video?.name + " را در خراسان مدیا تماشا کنید \n\n\n لینک " + `${type === 'video' ? ' ویدئو ' : ' موزیک ویدئو '}` + "\n",
                                                                url: type === 'video' ? `https://khorasanmedia.ir/video/${video?.category?.slug}/${video?.slug}` : "https://khorasanmedia.ir/music-video/" + video?.slug,
                                                            })
                                                        } else {
                                                            if (window.AndroidInterface) {
                                                                window.AndroidInterface.share('موزیک ویدئو ' + video?.name + " را در خراسان مدیا تماشا کنید \n\n\n لینک موزیک ویدئو \n" + (type === 'video' ? `https://khorasanmedia.ir/video/${video?.category?.slug}/${video?.slug}` : "https://khorasanmedia.ir/music-video/" + video?.slug));
                                                            }
                                                        }
                                                    } catch (error) {
                                                      
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                    <Accordion.Header>
                                        <div className='icon'>
                                            <i className="far fa-info" />
                                        </div>
                                    </Accordion.Header>
                                    {
                                        video?.song ?
                                            <div className='icon' onClick={() => {
                                                if (music?.slug === video.song?.slug) {
                                                    onTogglePlay()
                                                } else {
                                                    playMusichandler([video.song], 0)
                                                }
                                            }}>
                                                <i className="far fa-music" />
                                            </div>
                                            :
                                            null
                                    }
                                    <div className='icon'>
                                        {user
                                            ?
                                            <i onClick={() => { operator.onBookmark(video?.slug) }} className={`${video?.properties?.isBookmark ? 'fas' : 'far'}  ${operator.loading.bookmark ? 'spinner-border' : 'fa-bookmark'}`} />
                                            :
                                            <i onClick={() => navigate('/account')} className='far fa-bookmark' />
                                        }
                                    </div>
                                    <div className='icon'>
                                        <span className='mx-2'>{operator.activities.countDislike}</span>{/* dislike count */}
                                        {user
                                            ?
                                            <>
                                                <i onClick={() => { operator.onLike('dislike') }} className={`${operator.activities.statusDislike ? 'fas' : "far"} ${operator.loading.dislike ? 'spinner-border' : 'fa-thumbs-down'}`} />
                                            </>
                                            :
                                            <i onClick={() => navigate('/account')} className='far fa-thumbs-down' />
                                        }
                                    </div>
                                    <div className='icon'>
                                        <span className='mx-2'>{operator.activities.countLike}</span>{/* like count */}
                                        {user
                                            ?
                                            <>
                                                <i onClick={() => { operator.onLike('like') }} className={`${operator.activities.statusLike ? 'fas' : "far"} ${operator.loading.like ? 'spinner-border' : 'fa-thumbs-up'}`} />
                                            </>
                                            :
                                            <i onClick={() => navigate('/account')} className='far fa-thumbs-up' />
                                        }
                                    </div>
                                </div>
                                <Accordion.Body className='p-0'>
                                    <div className='informtoin px-3 pt-3'>
                                        <p>سال تولید : <span>{video?.info?.release_year}</span></p>
                                        <p>نویسنده متن : <span>{video?.info?.writer}</span></p>
                                        <p>آهنگساز : <span>{video?.info?.composer}</span></p>
                                        <p>تنظیم کننده : <span>{video?.info?.director}</span></p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}
