import axios from 'axios'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { BaseUrlAPI } from '../../../assets'

export const InfinityList = ({ url, render, query }) => {
    const [Loading, setLoading] = useState(false)
    const [countpages, setcountpages] = useState(1)
    const [list, setList] = useState([])
    const [page, setPage] = useState(0)
    const ref = useRef()

    const getData = useCallback(async () => {
        if (page <= countpages) {
            setLoading(true);
            await axios.get(`${BaseUrlAPI}${url}${query ? query + "&" : ''}${page === 0 ? "" : "page=" + page}`).then((res) => {
                console.log(res)
                if (res?.status === 200) {
                    setList([...list, ...res?.data?.data]);
                    setcountpages(Math.ceil(res.data?.total / 12))
                    setLoading(false);
                }
            })
        }
    }, [page, query]);

    useEffect(() => {
        getData()
    }, [page, query])

    const callback = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            if (page <= countpages) {
                setPage((prev) => prev + 1);
            }
        }
    }, []);
    useEffect(() => {
        const observer = new IntersectionObserver(callback)

        if (ref.current) {
            observer.observe(ref.current)
        }
    }, [callback])



    return (
        <Fragment>

            {
                list ?
                    <>
                        <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                            {
                                list?.map((item, i) => {
                                    return (
                                        render(item, i, list)
                                    )
                                })
                            }
                        </div>
                        {Loading &&
                            <div style={{ width: "100%" }} className="d-flex justify-content-center align-items-center mt-4">
                                <div className="spinner-border spinner-border-sm" style={{ color: "var(--primary)" }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>}
                        <div ref={ref}></div>

                    </>
                    :
                    <div className='text-center mt-4'>
                        <span>نتیجه ای یافت نشد‍‍!</span>
                    </div>
            }
        </Fragment>
    )
}

