import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import './style.scss'
import { Route, Routes } from 'react-router'
import { HeaderMusic } from '../../components'
import { Musics } from './musics'
import { Superior } from './superior'
import { Albums, Album } from './albums'
import { Artists } from './artists'
import { Categories, Category } from './categories'
import { Suggests } from './suggests'
export const Music = () => {

  return (
    <Fragment>
      <Helmet>
        <title>موسیقی</title>
      </Helmet>
      <Routes>
        <Route path='/' element={<HeaderMusic><Musics /></HeaderMusic>} />
        <Route path='/superior' element={<HeaderMusic><Superior /></HeaderMusic>} />
        <Route path='/albums' element={<HeaderMusic><Albums /></HeaderMusic>} />
        <Route path='/albums/:slug' element={<Album />} />
        <Route path='/artists' element={<HeaderMusic><Artists /></HeaderMusic>} />
        <Route path='/categories' element={<HeaderMusic><Categories /></HeaderMusic>} />
        <Route path='/categories/:slug' element={<Category />} />
        <Route path='/suggests' element={<HeaderMusic><Suggests /></HeaderMusic>} />
      </Routes>
    </Fragment>
  )
}
