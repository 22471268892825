import './style.scss'
import { images } from '../../assets'
import { CategoryImage } from '../../components/views'

export const Suggest = () => {
    const musics = [
        {
            name: 'برتر',
            pictuer: images.Musics,
            icon: 'fas fa-music',
            href: '/music/superior'
        },
        {
            name: 'همه',
            pictuer:images.news,
            icon: 'fas fa-random',
            href: '/music'
        },
        {
            name: 'دسته بندی',
            pictuer:images.Category,
            icon: 'far fa-list-music',
            href: '/music/categories'
        },
        {
            name: 'پیشنهادی',
            pictuer:images.Random,
            icon: 'fas fa-bolt',
            href: '/music/suggests'
        },
        {
            name: 'آلبوم ها',
            pictuer:images.Alboms,
            icon: 'fas fa-layer-group',
            href: '/music/albums'
        },
        {
            name: 'خواننده ها',
            pictuer:images.position,
            icon: 'fas fa-users',
            href: '/music/artists'
        }
    ]
    return (
        <div className='container'>
            <div className="Suggest">
                {
                    musics?.map((item, index) => {
                        return (
                            <div className="sizeBox p-2" key={index}>
                                <CategoryImage
                                    icon={<i className={`${item.icon}`} />}
                                    image={item.pictuer}
                                    label={item.name}
                                    style={{ borderRadius: "10px" }}
                                    to={item.href}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

