import React from 'react'
import { Result } from './result';
import { useSearch } from '../../hooks';
import { LoaderSingleLine } from '../../components';
import "./style.scss";
export const Search = () => {
    const { search, setQuery, onClear, input } = useSearch()
    return (
        <div className='searchPage overflow-hidden'>
            <div className="searchheader">
                <div className='searchinput'>
                    <input
                        type="text"
                        ref={input}
                        placeholder='حداقل دو کاراکتر وارد کنید ...'
                        onChange={(({ target: { value } }) => setQuery(value))}
                    />
                    <div>
                        <i className='icon ti-close mx-3' onClick={onClear}></i>
                    </div>
                </div>
            </div>

            {search.loading ?
                <div className="px-3">
                    <LoaderSingleLine count={[1, 2, 3, 4, 5]} />
                </div>
                : Array.isArray(search) ?
                    null
                    :
                    typeof search == 'object' ?
                        Object.entries(search).filter((item) => {
                            if (item[1]) {
                                if (item[1].length) {
                                    return item;
                                } else {
                                    return null;
                                }
                            } else {
                                return null;
                            }
                        }).length ?
                            <div className="row px-4">
                                <Result object={search} />
                            </div>
                            :
                            <div className='d-flex justify-content-center align-items-center my-4'>
                                <p>موردی پیدا نشد !</p>
                            </div>
                        :
                        null
            }


        </div>
    )
}
