import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../../assets';
import './style.scss';
import { useMusicContext } from '../../../contexts';

export function VerticalMusic({ colsize, cover, track_name, artist_name, to, className, artist_slug, resize, release_year, musicObject, playlist }) {
    const { source, onTogglePlay, statusPlay, playMusichandler } = useMusicContext()
    const Content = ({ resize, src }) => {
        return (
            <Fragment>
                <div onClick={() => {
                    if (musicObject && playlist) {
                        if (source?.slug === musicObject?.slug) {
                            onTogglePlay()
                        } else {
                            playMusichandler(playlist, [...playlist].findIndex(item => item.slug === musicObject?.slug))
                        }
                    }
                }} className={resize || 'image_music'}>
                    <img
                        onError={({ target }) => { target.src = images.default }}
                        src={src}
                        width={250}
                        height={250}
                        style={{ objectFit: "cover" }}
                        alt='pictuer'
                    />
                    {
                        source?.slug === musicObject?.slug
                            &&
                            statusPlay ?

                            <div style={{ color: "var(--primary)" }} className="loadering position-absolute spinner-grow spinner-grow-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            null
                    }
                </div>
                <div className='lable_footer'>
                    <p className="m-0">{release_year}</p>
                </div>
            </Fragment>
        )
    }

    return (
        <div className={`item_music col-6 col-md-${colsize} col-sm-4 ${className} `}>
            {to
                ?
                <Link to={to} className='content' >
                    <Content resize={resize} src={cover} />
                </Link>
                :
                <div className='content' >
                    <Content resize={resize} src={cover} />
                </div>
            }
            <div className='item_description'>
                <div className='name_music'>
                    <span>
                        {track_name}
                    </span>
                </div>
                <div className='artist_music'>
                    <Link to={`/artist/${artist_slug}`} >
                        <span>
                            {artist_name}
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

